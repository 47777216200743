import { call, select, takeEvery, takeLatest } from "redux-saga/effects";
import * as filesApi from "../api/fetchFiles";
import {
  DELETE_DOCUMENT,
  DOWNLOAD_DOCUMENT,
  FETCH_DOCUMENT,
  FETCH_IMAGE,
  FETCH_PIECES,
} from "./actions";
import {
  getBulletinsSalaire,
  getPiecesIdentites,
  getPiecesOtherThanSalaires,
} from "../helpers/dossierNumeriqueHelper";

import { toast } from "react-toastify";
import { selectAdministrateurBien } from "redux/administrateurBien/administrateurBienReducer";
import {
  selectCurrentDossierLocataire,
  selectCurrentSite,
  selectDossierLocationId,
} from "redux/dossierLocation/dossierLocationReducer";

function* fetchImageFromApi(action) {
  const {
    id,
    onSuccessAction = () => {},
    onErrorAction = () => {},
    closeLoaderAction = () => {},
    onNoImageAction = () => {},
    dossierNumeriqueId,
  } = action.payload;

  try {
    const response = yield call(
      filesApi.fetchDocument,
      dossierNumeriqueId,
      id,
      true
    );
    if (response.status === 200) {
      if (response.data.size !== 0) {
        let url = URL.createObjectURL(response.data);
        onSuccessAction(url);
      }
    }
  } catch (error) {
    if (error && error.message && error.message === "canceled") {
      onNoImageAction();
      return;
    }
    if (!error.response) {
      onErrorAction("Error fetching image");
    } else if (error.response.status !== 404) {
      onErrorAction("Error fetching image");
    } else {
      onNoImageAction();
    }
  } finally {
    closeLoaderAction();
  }
}

function* downloadDocument(action) {
  const {
    numeriqueId,
    onFetchError = () => {},
    onFetchChange = () => {},
    dossierNumeriqueId,
  } = action.payload;

  try {
    onFetchChange(true);

    if (!numeriqueId) {
      onFetchError("Aucune numerique id saisie");
      onFetchChange(false);
      return;
    } else if (!dossierNumeriqueId) {
      onFetchError("Aucun dossier numerique saisie");
      onFetchChange(false);
      return;
    }

    const response = yield call(
      filesApi.fetchDocument,
      dossierNumeriqueId,
      numeriqueId,
      false
    );
    if (response.status === 200) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.setAttribute("download", `${numeriqueId}.pdf`);
      link.setAttribute("href", url);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else if (response.status === 404) {
      onFetchError("Not found");
    } else if (response.status === 401) {
      onFetchError("Unauthorized");
    } else if (response.status === 403) {
      onFetchError("Forbidden");
    } else if (response.status === 503) {
      onFetchError("Service unavailable");
    } else if (response.status === 500) {
      onFetchError("Internal server error");
    }
  } catch (error) {
    toast.error("Une erreur est survenue lors du téléchargement du document");
  } finally {
    onFetchChange(false);
  }
}

function* fetchDocumentforPopup(action) {
  const {
    numeriqueId,
    dossierNumeriqueId,
    onFetchError = () => {},
    onFetchChange = () => {},
    onFetchSuccess = () => {},
  } = action.payload;

  try {
    onFetchChange(true);

    if (!numeriqueId) {
      onFetchError("No numerique id provided");
      onFetchChange(false);
      return;
    } else if (!dossierNumeriqueId) {
      onFetchError("No dossier locataire id provided");
      onFetchChange(false);
      return;
    }

    const response = yield call(
      filesApi.fetchDocument,
      dossierNumeriqueId,
      numeriqueId,
      true
    );
    if (response.status === 200) {
      onFetchSuccess(response.data);
    } else if (response.status === 404) {
      onFetchError("Not found");
    } else if (response.status === 401) {
      onFetchError("Unauthorized");
    } else if (response.status === 403) {
      onFetchError("Forbidden");
    } else if (response.status === 503) {
      onFetchError("Service unavailable");
    } else if (response.status === 500) {
      onFetchError("Internal server error");
    }
  } catch (error) {
    toast.error("Une erreur est survenue lors de la récupération du document");
  } finally {
    onFetchChange(false);
  }
}

function fetchPieces(action) {
  const { onChange = () => {} } = action.payload;
  try {
    onChange(true);
    const {
      setPiecesIdentites = () => {},
      setFichesPaie = () => {},
      setAutresPieces = () => {},

      dossierNumerique,
    } = action.payload;

    setPiecesIdentites(getPiecesIdentites(dossierNumerique));
    setFichesPaie(getBulletinsSalaire(dossierNumerique));
    setAutresPieces(getPiecesOtherThanSalaires(dossierNumerique));
  } catch (error) {
    console.log(error);
  } finally {
    onChange(false);
  }
}

function* deleteDocument(action) {
  const { numeriqueId, setFileProcessing, onProcessingSuccess } =
    action.payload;

  try {
    const { id: adbId } = yield select(selectAdministrateurBien);
    const { id: siteId } = yield select(selectCurrentSite);
    const locationId = yield select(selectDossierLocationId);
    const { id: dossierLocataireId } = yield select(
      selectCurrentDossierLocataire
    );
    setFileProcessing(true);

    yield call(
      filesApi.deleteDocument,
      adbId,
      siteId,
      locationId,
      dossierLocataireId,
      numeriqueId
    );
    onProcessingSuccess();
  } catch (error) {
    toast.error("Une erreur est survenue lors de la suppression du document");
  } finally {
    setFileProcessing(false);
  }
}

function* fetchImage() {
  yield takeEvery(FETCH_IMAGE, fetchImageFromApi);
}

function* downloadDocumentCall() {
  yield takeEvery(DOWNLOAD_DOCUMENT, downloadDocument);
}

function* deleteDocumentCall() {
  yield takeEvery(DELETE_DOCUMENT, deleteDocument);
}

function* fetchDocument() {
  yield takeEvery(FETCH_DOCUMENT, fetchDocumentforPopup);
}

function* fetchFiles() {
  yield takeLatest(FETCH_PIECES, fetchPieces);
}

export {
  fetchFiles,
  fetchDocument,
  fetchImage,
  downloadDocumentCall,
  deleteDocumentCall,
};
