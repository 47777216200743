import { call, put, takeLatest } from "redux-saga/effects";
import { setCurrentUser } from "redux//user/userReducer";
import { setCurrentAdministrateurBien } from "redux//administrateurBien/administrateurBienReducer";
import * as api from "../api/utilisateur";
import { TYPE_ADB } from "../constantes";
import { UTILISATEUR_LOAD } from "./actions";
import { toast } from "react-toastify";

// Worker saga will be fired on USER_FETCH_REQUESTED actions
function* getConnectedUser() {
  try {
    const connectedUser = yield call(api.getUtilisateurConnecte);
    const utilisateur = connectedUser.data;
    yield put(setCurrentUser(utilisateur));
    if (utilisateur.typeCompte === TYPE_ADB) {
      yield put(setCurrentAdministrateurBien(utilisateur.administrateurBien));
    }
  } catch (error) {
    console.log(error);
    toast.error(
      "Une erreur s'est produite, Veuillez contacter votre administrateur pour plus d'informations."
    );
  }
}

// Starts fetchUser on each dispatched USER_FETCH_REQUESTED action
// Allows concurrent fetches of user
function* loadUser() {
  yield takeLatest(UTILISATEUR_LOAD, getConnectedUser);
}

export { loadUser };
