import { axiosInstance } from "./axiosInstance";

export const getDossierNumerique = (dossierNumeriqueId) => {
  return axiosInstance.get(`/v2/dossiers-numeriques/${dossierNumeriqueId}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const fetchDocument = (
  dossierNumeriqueId,
  pieceNumeriqueId,
  isImage
) => {
  return axiosInstance.get(
    `/v2/dossiers-numeriques/${dossierNumeriqueId}/pieces-numeriques/${pieceNumeriqueId}` + (isImage ? "?image=true" : ""),
    {
      responseType: isImage ? "blob" : "arraybuffer",
    }
  );
};


export const deleteDocument = (
  adbId,
  siteId,
  lcoationId,
  locataireId,
  pieceNumeriqueId
) => {
  return axiosInstance.delete(
    `/administrateur-biens/${adbId}/sites/${siteId}/dossier-locations/${lcoationId}/dossier-locataires/${locataireId}/dossier-numerique/piece-numeriques/${pieceNumeriqueId}`
  );
};
