import styled, { css, keyframes, withTheme } from "styled-components";
const load1 = keyframes`
    0%,
    80%,
    100% {
    box-shadow: 0 0;
    height: 4em;
    }
    40% {
    box-shadow: 0 -2em;
    height: 5em;
    }
`;

export const SpinnerContainer = styled.div`
  color: ${({ theme, color }) =>
    color === "white"
      ? "#fff"
      : color === "default"
      ? theme.colors.primary
      : theme.colors[color]};
  text-indent: -9999em;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  height: 56px;
  max-height: 56px;
  min-height: 56px;
  &,
  &:before,
  &:after {
    background: ${({ theme, color }) =>
      color === "white"
        ? "#fff"
        : color === "default"
        ? theme.colors.primary
        : theme.colors[color]};
    -webkit-animation: ${load1} 1s infinite ease-in-out;
    animation: ${load1} 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
  }
  &:before,
  &:after {
    position: absolute;
    top: 0;
    content: "";
  }
  &:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  &:after {
    left: 1.5em;
  }
  ${({ size, hasMessage }) => {
    if (size === "default") {
      css``;
    } else if (size === "small") {
      return css`
        transform: scale(0.4) translateY(-28px);
      `;
    } else if (size === "tiny") {
      return css`
        height: 24px;
        max-height: 24px !important;
        transform: scale(0.3)
          ${hasMessage ? "translateY(0px)" : "translateY(-28px)"};
      `;
    }
  }}
`;
export const SpinnerOuter = styled.div`
  color: ${({ theme, color }) =>
    color === "white" ? "#fff" : theme.colors.primary};
  display: flex;
  gap: 1rem;
  flex-direction: ${({ messagePosition }) => messagePosition};
  align-items: center;
  justify-content: center;
  & > span {
    color: ${({ theme, color }) =>
      color === "white" ? "#fff" : theme.colors.primary};
    font: ${({ theme }) => theme.fonts.montserrat.regular};
    font-weight: 600;
    text-align: center !important;
  }
  ${({ size, marginX, marginY }) => {
    if (size === "default") {
      return css`
        margin: ${marginY} ${marginX} !important;
        & > span {
          margin-top: ${({ theme }) => theme.margins[4]};
        }
      `;
    } else if (size === "small") {
      return css`
        margin: 0 16px;
        & > span {
          margin-top: -${({ theme }) => theme.margins[2]};
        }
      `;
    } else if (size === "tiny") {
      return css`
        height: 24px;
        margin-bottom: -${({ theme, hasMessage }) => (!hasMessage ? theme.margins[3] : 0)};
      `;
    }
  }}
`;

const Spinner = ({
  message,
  size = "default",
  marginX = "auto",
  marginY = "88px",
  color = "default",
  messagePosition = "column",
}) => {
  return (
    <SpinnerOuter
      size={size}
      marginX={marginX}
      marginY={marginY}
      color={color}
      messagePosition={messagePosition}
      hasMessage={message}
    >
      <SpinnerContainer size={size} color={color} hasMessage={message} />
      {message && <span>{message}</span>}
    </SpinnerOuter>
  );
};

export default withTheme(Spinner);
