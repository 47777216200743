import { axiosInstance } from "utils/api/axiosInstance";

export const getDossierFacileNumerique = async (dossierNumeriqueId) => {
  return axiosInstance.get(`/dossier-numerique-facile/${dossierNumeriqueId}`);
};

export const updateDossierFacileIdentityType = async (
  dossierNumeriqueId,
  itemId,
  identityType
) => {
  return axiosInstance.put(`/dossier-numerique-facile/${dossierNumeriqueId}`, {
    id: itemId,
    typePieceIdentite: identityType,
  });
};

export const FetchDossierFacileDocument = async (
  dossierNumeriqueId,
  pieceId,
  isImage
) => {
  return axiosInstance.get(
    `/dossier-numerique-facile/${dossierNumeriqueId}/pieces-numeriques/${pieceId}` +
      (isImage ? "?image=true" : ""),
    {
      responseType: "arraybuffer",
    }
  );
};
