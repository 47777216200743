import PopupOuterContainer from "../popups";
import {
  TypeSelectCategory,
  TypeSelectText,
  TypeSelectTitle,
} from "./locataireBailleurPopupStyles";
import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import { ReactComponent as BrokerIcon } from "assets/icons/broker.svg";
import {
  TYPE_PARTICULIER_BAILLEUR,
  TYPE_PARTICULIER_CANDIDAT,
} from "utils/constantes";
import { withTheme } from "styled-components";

function LocataireBailleurPopup({ showPopup, setShowPopup }) {
  const preferredRole =
    localStorage.getItem("preferredRole") || TYPE_PARTICULIER_CANDIDAT;
  const setPreferredRole = (role) => {
    localStorage.setItem("preferredRole", role);
    setShowPopup(false);
    window.location.reload();
  };

  return (
    <PopupOuterContainer
      showPopup={showPopup}
      heightConstraint="max-height"
      setShowPopup={setShowPopup}
    >
      <div>
        <TypeSelectTitle>Sélection de Rôle</TypeSelectTitle>
        <TypeSelectText>
          Ici, vous pouvez définir votre rôle préféré pour LOQK
        </TypeSelectText>
        <TypeSelectCategory
          onClick={() => setPreferredRole(TYPE_PARTICULIER_CANDIDAT)}
          active={preferredRole === TYPE_PARTICULIER_CANDIDAT}
        >
          <UserIcon />
          <p>Je suis locataire</p>
          <span />
        </TypeSelectCategory>
        <TypeSelectCategory
          onClick={() => setPreferredRole(TYPE_PARTICULIER_BAILLEUR)}
          active={preferredRole === TYPE_PARTICULIER_BAILLEUR}
        >
          <BrokerIcon />
          <p>Je suis bailleur</p>
          <span />
        </TypeSelectCategory>
      </div>
    </PopupOuterContainer>
  );
}

export default withTheme(LocataireBailleurPopup);
