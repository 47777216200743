import styled, { css } from "styled-components";

export const CategoriesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  margin-top: ${({ theme }) => theme.margins[8]};
  margin-bottom: ${({ theme }) => theme.margins[12]};
  padding: ${({ theme }) => theme.paddings[6]};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin: ${({ theme }) => theme.margins[8]} auto 0;
    position: relative;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 0;
    margin-bottom: ${({ theme }) => theme.margins[8]} !important;
  }
`;

export const UploadLock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 8px;
  cursor: not-allowed;
  z-index: 53;
  overflow: hidden;
  img {
    width: 65%;
    transform: rotate(10deg);
    filter: grayscale();
    position: absolute;
    bottom: -50%;
    right: -5%;
    opacity: 0.18;
    transition: 500ms ease-in-out !important;
  }
  svg {
    width: 40px;
    height: 40px;
    path {
      fill: ${({ theme }) => theme.colors.textOnPrimary};
    }
  }
  p {
    font: ${({ theme }) => theme.fonts.montserrat.small};
    font-weight: 600 !important;
    color: ${({ theme }) => theme.colors.textOnPrimary} !important;
    width: 75%;
    text-align: center;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    & > img {
      width: 100%;
      max-width: 320px;
    }
  }
`;

export const GarantWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;

  & > svg {
    margin: 0 ${({ theme }) => theme.margins[3]};
    width: 24px !important;
    height: 24px !important;
    min-width: 24px !important;
    min-height: 24px !important;
  }
  & > div {
    display: flex;
    gap: 16px;
    align-items: center;
    opacity: ${({ active }) => (active ? "1" : "0.5")};
    cursor: pointer;
    transition: 500ms ease-in-out;
    & > svg {
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
    }
    & > p {
      position: relative;
      font: ${({ theme }) => theme.fonts.montserrat.regular};
      font-weight: 600 !important;
      color: ${({ theme }) => theme.colors.garant} !important;
      span {
        position: absolute;
        opacity: 0;
        visibility: hidden;
        top: 0;
        font: ${({ theme }) => theme.fonts.montserrat.small};
        font-weight: 600 !important;
        transition: 300ms ease-in-out;
      }
    }
    &:hover {
      & > p {
        span {
          top: -16px;
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-direction: column;
    & > svg {
      margin: 0;
    }
  }
`;

export const CategoryOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    align-items: flex-start;
    max-height: ${({ showOptions }) => (showOptions ? "1200px" : "0")};
    transition: 500ms ease-in-out;
    overflow: hidden;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    visibility: ${({ showOptions }) => (showOptions ? "visible" : "hidden")};
    width: 95%;
    background: ${({ theme }) => theme.colors.textOnPrimary};
    gap: 0;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 8px;
    z-index: 60;
    & > * {
      transition: 500ms ease-in-out;
      width: 100%;
      padding: ${({ theme }) => theme.paddings[6]}
        ${({ theme }) => theme.paddings[4]};
      &:hover {
        background: #f0f0f0;
      }
    }
  }
`;

export const AttachmentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 4px 0 8px;
  p {
    font: ${({ theme }) => theme.fonts.montserrat.regular};
    color: ${({ theme }) => theme.colors.primaryOpaque};
    font-weight: 600 !important;
  }
  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: 0.5;
    transition: 0.3s ease-in-out;
    &:hover {
      opacity: 1;
    }
  }
`;

export const ActiveCategory = styled.div`
  padding: ${({ theme }) => theme.paddings[4]}
    ${({ theme }) => theme.paddings[4]};
  border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  width: 100%;
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  svg {
    transform: ${({ showOptions }) =>
      showOptions ? "rotate(180deg)" : "rotate(0deg)"};
    transition: 500ms ease-in-out;
    width: 22px;
    height: 22px;
  }
`;

export const DashboardNoResults = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.paddings[12]} 0;
  gap: 16px;
  svg {
    width: 56px;
    height: 56px;
  }
  p {
    font: ${({ theme }) => theme.fonts.montserrat.regular};
    font-weight: 600 !important;
    color: ${({ theme }) => theme.colors.primaryOpaque};
    margin: 0;
  }
`;

export const SpinnerContainer = styled.div`
  & > div {
    font-size: 8px;
    height: 30px;
    min-height: 30px;
    max-height: 30px;
  }
`;

export const WarningContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: ${({ theme }) => theme.paddings[12]};
  width: 80%;
  margin-bottom: ${({ theme }) => theme.margins[4]};
  h3 {
    font: ${({ theme }) => theme.fonts.montserrat.small};
    font-weight: 600 !important;
    color: ${({ theme }) => theme.colors.error};
    margin: 0 0 ${({ theme }) => theme.margins[2]} 0;
    transform: translateX(-24px);
    margin: 0;
  }
  p {
    color: ${({ theme }) => theme.colors.error};
    font: ${({ theme }) => theme.fonts.montserrat.small};
    font-weight: 500 !important;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 8px;
      left: -16px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: ${({ theme }) => theme.colors.error};
      display: block;
    }
  }
`;

export const SyntheseErrors = styled.div`
  width: 80%;
  & > div {
    padding-left: ${({ theme }) => theme.paddings[12]};
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  h3 {
    font: ${({ theme }) => theme.fonts.montserrat.regular};
    font-weight: 600 !important;
    color: ${({ theme }) => theme.colors.error};
    margin: 0 0 ${({ theme }) => theme.margins[4]} 0;
  }
  p {
    color: ${({ theme }) => theme.colors.error} !important;
    font: ${({ theme }) => theme.fonts.montserrat.regular} !important;
    font-weight: 500 !important;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 8px;
      left: -16px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: ${({ theme }) => theme.colors.error};
      display: block;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    width: 100%;
    & > div {
      padding-left: ${({ theme }) => theme.paddings[4]};
    }
  }
`;

export const SyntheseFile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 0 0 ${({ theme }) => theme.margins[6]};

  & > div {
    &:first-of-type {
      display: flex;
      align-items: center;
      gap: 16px;
      margin: 0;

      & > p {
        font: ${({ theme }) => theme.fonts.montserrat.regular};
        font-weight: 600 !important;
        color: ${({ theme, error }) =>
          error ? theme.colors.error : theme.colors.primaryOpaque};
      }
    }
    &:last-of-type {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 24px;
      /* max-width: 720px; */
    }
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.largeDesktop}) {
    & > div {
      &:last-of-type {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    margin: 0 0 ${({ theme }) => theme.margins[4]};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    & > div:last-of-type {
      grid-template-columns: 1fr;
    }
    /* & > div:first-of-type {
      display: flex;
      flex-direction: column;

      & > div {
        &:nth-of-type(1) {
          margin-left: 0;
        }
      }
    } */
  }
`;

export const CollapsibleContainer = styled.div`
  max-height: ${({ collapsed, hasButton }) =>
    !collapsed ? "100%" : hasButton ? "56px" : "28px"};
  overflow: hidden;
  transition: 500ms ease-in-out;
  & > div {
    &:last-of-type {
      opacity: ${({ collapsed }) => (!collapsed ? "1" : "0")};
      transition: 500ms ease-in-out;
      padding-left: ${({ theme }) => theme.paddings[12]};
      transition-delay: ${({ collapsed }) => (!collapsed ? "300ms" : "0ms")};
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    max-height: ${({ collapsed, hasButton }) =>
      !collapsed ? "100%" : hasButton ? "76px" : "28px"};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-height: ${({ collapsed, hasButton }) =>
      !collapsed ? "100%" : hasButton ? "106px" : "28px"};
    & > div {
      &:last-of-type {
        padding-left: ${({ theme }) => theme.paddings[2]};
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    max-height: ${({ collapsed, hasButton }) =>
      !collapsed ? "100%" : hasButton ? "166px" : "28px"};
  }
`;

export const Candidates = styled.div`
  display: flex;
  ${({ overflowCandidates }) =>
    overflowCandidates &&
    css`
      overflow-x: auto;
    `}
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
    padding: ${({ theme }) => theme.paddings[6]};
    position: relative;
    overflow-x: clip;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 0;
    margin-bottom: ${({ theme }) => theme.margins[6]};
  }
`;

export const RevenusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  margin-bottom: ${({ theme }) => theme.margins[19]};
  & > div {
    &:first-of-type {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      h1 {
        font: ${({ theme }) => theme.fonts.montserrat.heading};
        font-weight: 600 !important;
        color: ${({ theme }) => theme.colors.primary};
        margin-bottom: ${({ theme }) => theme.margins[3]};
      }
      p {
        font: ${({ theme }) => theme.fonts.montserrat.regular};
        color: ${({ theme }) => theme.colors.primaryOpaque};
        max-width: 490px;
        font-weight: 600 !important;
      }
    }
    &:nth-of-type(2) {
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 72px;
      max-height: 100%;
      overflow: hidden;
      transition: 500ms ease-in-out;
      max-width: 1024px;
      & > div {
        margin-bottom: ${({ theme }) => theme.margins[12]};
      }
      ${({ isSubmitting }) =>
        isSubmitting &&
        css`
          opacity: 0;
          pointer-events: none;
          max-height: 0;
        `}
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    & > div {
      &:nth-of-type(2) {
        flex-direction: column;
        max-width: unset;
        & > div {
          max-width: unset;
          margin-bottom: ${({ theme }) => theme.margins[4]};
        }
      }
    }
  }
`;

export const VisaleContainer = styled.div`
  height: 24px;
  width: 24px;
  svg {
    width: 24px;
    height: 24px;
  }
`;

export const CandidateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: ${({ theme }) => theme.paddings[3]}
    ${({ theme }) => theme.paddings[8]};
  position: relative;
  & > p {
    position: absolute;
    width: 100%;
    top: -64px;
  }
  img {
    width: 24px;
    height: 24px;
  }
  &::before {
    content: "";
    width: 100%;
    height: 2px;
    background: ${({ theme }) => theme.colors.primary};
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: ${({ active }) => (active ? "100%" : "0")};
    transition: 400ms ease-in-out;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    &::before {
      display: none;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-direction: column;
  }
`;

export const Candidate = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  opacity: ${({ active }) => (active ? "1" : "0.5")};
  transition: 0.3s ease-in-out;
  cursor: pointer;

  p {
    font: ${({ theme }) => theme.fonts.montserrat.regular};
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600 !important;
    &:first-of-type {
      max-width: 25ch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  /* @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: ${({ active }) => (active ? "none" : "flex")};
  } */
`;

export const ActiveCandidate = styled.div`
  padding: ${({ theme }) => theme.paddings[4]}
    ${({ theme }) => theme.paddings[4]};
  border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  width: 100%;
  display: none;
  & > div {
    display: flex !important;
    padding: 0;
    &::before {
      display: none !important;
    }
  }
  ${({ forceMenu }) =>
    forceMenu &&
    css`
      display: flex;
      align-items: center;
    `}
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: flex;
    align-items: center;
  }
  & > svg {
    margin-left: auto;
    transform: ${({ showOptions }) =>
      showOptions ? "rotate(180deg)" : "rotate(0deg)"};
    transition: 500ms ease-in-out;
  }
`;

export const CandidateOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    align-items: flex-start;
    max-height: ${({ showOptions }) => (showOptions ? "1200px" : "0")};
    transition: 500ms ease-in-out;
    overflow: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    visibility: ${({ showOptions }) => (showOptions ? "visible" : "hidden")};
    width: 100%;
    background: ${({ theme }) => theme.colors.textOnPrimary};
    gap: 0;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 8px;
    z-index: 15;
    & > * {
      transition: 500ms ease-in-out;
      width: 100%;
      padding: ${({ theme }) => theme.paddings[6]}
        ${({ theme }) => theme.paddings[4]};
      &:hover {
        background: #f0f0f0;
      }
    }
  }
`;

export const FileInfoMessage = styled.div`
  width: 24px;
  height: 24px;
  svg {
    width: 24px;
    height: 24px;
  }
  div {
    display: ${({ collapse }) => (collapse ? "none" : "block")};

    svg {
      margin-bottom: ${({ theme }) => theme.margins[2]};
      width: 32px;
      height: 32px;
    }
    font: ${({ theme }) => theme.fonts.montserrat.regular};
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600 !important;
    padding: ${({ theme }) => theme.paddings[6]}
      ${({ theme }) => theme.paddings[8]};
    border-radius: 8px;
    position: absolute;
    top: 77px;
    left: 0;
    width: 100%;
    overflow: hidden;
    visibility: hidden;
    max-height: 0;
    opacity: 0;
    transition: 500ms ease-in-out;
    background: ${({ theme }) => theme.colors.textOnPrimary};
    box-shadow: ${({ theme }) => theme.shadows.lightDark};
    z-index: 55;
    height: 100%;
    p {
      color: ${({ theme }) => theme.colors.primaryOpaque};
      margin-top: ${({ theme }) => theme.margins[1]};
      width: 90%;
    }
  }
  & > svg:hover + div {
    visibility: visible;
    opacity: 1;
    max-height: calc(100% - 77px);
  }
`;

export const FileCategoryHeading = styled.div`
  padding: ${({ theme }) => theme.paddings[6]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    font: ${({ theme }) => theme.fonts.montserrat.highlight};
    margin: 0;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600 !important;
  }
  & > div {
    display: flex;
    align-items: center;
    gap: 16px;
    svg {
      cursor: pointer;
    }
    & > svg {
      transform: ${({ showOptions }) =>
        showOptions ? "rotate(0deg)" : "rotate(180deg)"};
      transition: 500ms ease-in-out;
    }
  }
`;

export const FileCategoryContainer = styled.div`
  background: linear-gradient(160.9deg, #fdfbfb 2.15%, #eef1f5 116.37%);
  border-radius: 8px;
  position: relative;
  height: max-content;
  & > div {
    transition: 500ms ease-in-out;
    &:nth-of-type(2n) {
      display: flex;
      flex-direction: column;
      gap: 32px;
      padding: ${({ theme }) => theme.paddings[6]};
    }
    &:nth-of-type(3) {
      margin-top: ${({ theme }) => theme.margins[4]};
    }
  }
  ${({ collapse }) =>
    collapse &&
    css`
      max-height: ${({ collapse }) => (collapse ? "76px" : "100%")};
      transition: 600ms ease-in-out;
      /* overflow: hidden; */
      & > div {
        &:nth-of-type(2) {
          visibility: ${({ collapse }) => (collapse ? "hidden" : "visible")};
          opacity: ${({ collapse }) => (collapse ? "0" : "1")};
          transition: 600ms ease-in-out;
          transition-delay: ${({ collapse }) => (collapse ? "0" : "200ms")};
        }
      }
      ${FileInfoMessage} {
        & > div {
          visibility: ${({ collapse }) => (collapse ? "hidden" : "visible")};
        }
      }
    `}
`;

export const UploadContainer = styled.div`
  position: relative;
  z-index: 5;
  & > p {
    font: ${({ theme }) => theme.fonts.montserrat.regular};
    color: ${({ theme }) => theme.colors.primaryOpaque};
    font-weight: 600 !important;
    margin: 0 0 ${({ theme }) => theme.margins[3]};
  }
`;

export const Action = styled.div`
  position: relative;
  cursor: pointer;
  height: 28px;
  width: 28px;
  svg {
    width: 28px;
    height: 28px;
  }
  &::before {
    content: ${({ text }) => `"${text}"`};
    position: absolute;
    top: 50%;
    left: 110%;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    z-index: 30;
    border-radius: 8px;
    transform: translateY(-50%);
    font: ${({ theme }) => theme.fonts.montserrat.small};
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 600 !important;
    padding: ${({ theme }) => theme.paddings[2]}
      ${({ theme }) => theme.paddings[4]};
    background: ${({ theme }) => theme.colors.textOnPrimary};
    user-select: none;
    opacity: 0;
    transition: 0.3s ease-in-out;
    visibility: hidden;
    white-space: nowrap;
  }
  &:hover {
    &::before {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const CustomNavigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.paddings[8]} 0 0;

  a {
    margin: ${({ theme }) => theme.margins[8]} 0 !important;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    flex-direction: column;
    ${({ ignoreMargin }) =>
      ignoreMargin === "bottom" &&
      css`
        a {
          margin: 0 !important;
        }
      `};
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 5;
  position: relative;
  input {
    display: none;
  }
  label {
    cursor: pointer;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-direction: row;
  }
`;

export const DocumentPreviewSmall = styled.div`
  label {
    margin-top: ${({ theme }) => theme.margins[6]};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: ${({ theme }) => theme.colors.primary};
    h2 {
      font: ${({ theme }) => theme.fonts.montserrat.regular};
      font-weight: 600 !important;
      margin: ${({ theme }) => theme.margins[4]} 0
        ${({ theme }) => theme.margins[2]};
    }
    p {
      font: ${({ theme }) => theme.fonts.montserrat.small};
      font-weight: 600 !important;
      color: ${({ theme }) => theme.colors.primaryOpaque};
      margin: 0;
    }
    input {
      display: none;
    }
  }
`;

export const FileAndInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  position: relative;
  z-index: 50;
  & > div {
    height: 160px;
    width: 135px;
    margin: 0 0 ${({ theme }) => theme.margins[2]} 0;
    display: block;
  }
  img {
    height: 160px;
    width: 135px;

    display: block;
  }

  p {
    color: ${({ theme }) => theme.colors.primaryOpaque};
    font: ${({ theme }) => theme.fonts.inter.small};
    font-weight: 600 !important;
    margin: 0;
  }
`;

export const DocumentTextureContainer = styled.div`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
  display: block !important;
  & > img {
    width: 65%;
    transform: rotate(10deg);
    filter: grayscale();
    position: absolute;
    bottom: -50%;
    right: -5%;
    opacity: 0.18;
    transition: 500ms ease-in-out !important;
    min-width: 210px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    & > img {
      width: 100%;
      max-width: 260px;
    }
  }
`;

export const FilePreviewAndActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 100%;

  padding: ${({ theme }) => theme.paddings[4]}
    ${({ theme }) => theme.paddings[4]};
  border-radius: 4px;
  position: relative;
  img {
    object-fit: contain;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-direction: column;
    gap: 24px;
  }
`;

export const NoFileUploaded = styled.label`
  width: 100%;
  padding: ${({ theme }) => theme.paddings[12]} 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &,
  * {
    transition: all 0.25s ease-in-out;
  }
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: ${({ theme }) => theme.colors.primary};
    position: relative;
    z-index: 5;
    svg {
      width: 32px;
      height: 32px;
      margin-bottom: ${({ theme }) => theme.margins[3]};
    }
    h2 {
      font: ${({ theme }) => theme.fonts.montserrat.regular};
      font-weight: 600 !important;
      margin: 0 0 ${({ theme }) => theme.margins[2]} !important;
    }
    p {
      font: ${({ theme }) => theme.fonts.montserrat.small};
      font-weight: 600 !important;
      color: ${({ theme, uploadNotAllowed }) =>
        uploadNotAllowed ? theme.colors.primary : theme.colors.primaryOpaque};
      margin: 0;
      width: 80%;
      text-align: center;
    }
    input {
      display: none;
    }
  }
  ${({ blocked }) =>
    blocked &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `};
  &:hover {
    ${({ blocked, uploadNotAllowed, theme }) => {
      if (!blocked) {
        return css`
          & > div {
            path {
              ${uploadNotAllowed ? "fill" : "stroke"}: ${theme.colors
                .textOnPrimary} !important;
            }
            h2 {
              color: ${theme.colors.textOnPrimary};
            }
            p {
              color: ${theme.colors.textOnPrimary};
            }
          }
          & > div > img {
            transform: rotate(15deg);
          }
        `;
      }
    }}
  }
`;

export const SyntheseContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 1640px;
  margin: 0 auto;
  @media screen and (max-width: 1024px) {
    padding-bottom: 144px;
  }
`;

export const SyntheseInfo = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
`;

export const FilesUnique = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const FilesUploadContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1340px;
  & > div {
    &:first-of-type {
      display: grid;
      gap: 72px;
      ${({ categoryColumns = 3 }) =>
        categoryColumns &&
        css`
          grid-template-columns: repeat(${categoryColumns}, 1fr);
        `}
      &>div {
        &:nth-of-type(2) {
          grid-row-start: 1;
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-end: ${({ categoryColumns, salaryFiles }) =>
            categoryColumns === 2 ? "2" : salaryFiles + 1};
        }
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    & > div {
      &:first-of-type {
        gap: 32px;
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    & > div {
      &:first-of-type {
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;
        & > div {
          ${({ ignoreRow }) =>
            !ignoreRow &&
            css`
              &:nth-of-type(3) {
                grid-area: 2/1/2/3;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                & > div {
                  &:nth-of-type(1) {
                    grid-area: 1/1/1/2;
                  }
                  &:nth-of-type(3) {
                    grid-area: 1/2/1/3;
                    margin-top: 0;
                  }
                }
              }
            `}
        }
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    & > div {
      &:first-of-type {
        display: flex;
        flex-direction: column;
        & > div {
          &:nth-of-type(3) {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

export const Separator = styled.span`
  width: 24px;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.primary};
  display: block;
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  transition: 500ms ease-in-out;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  & > div {
    width: 100%;
  }
  & > button {
    margin-top: ${({ theme }) => theme.margins[4]};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

export const InputsRow = styled.div`
  & > label {
    font: ${({ theme }) => theme.fonts.montserrat.regular};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold} !important;
    color: ${({ theme }) => theme.colors.primaryOpaque};
    margin-bottom: ${({ theme }) => theme.margins[2]};
    ${({ disabled }) =>
      disabled &&
      css`
        opacity: 0.5;
        cursor: not-allowed !important;
      `}
  }
  & > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
    max-width: 400px;
    & > div {
      max-width: 184px;
      & > div {
        gap: 4px;
        padding: ${({ theme }) => theme.paddings[2]}
          ${({ theme }) => theme.paddings[3]};
      }
    }
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      grid-template-columns: 1fr;
      gap: 16px;
      & > div {
        max-width: 100%;
      }
    }
  }
`;

export const CandidateInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 56px;
  width: 100%;
`;

export const InformationHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  margin: 0 0 ${({ theme, collapsed }) => !collapsed && theme.margins[8]};
  transition: 600ms ease-in-out;
  & > div {
    display: flex;
    align-items: center;
    gap: 24px;
    &:first-of-type {
      &>div{
        display: flex;
        align-items: center;
        gap: 16px;
        cursor: pointer;
        &>svg{
          transform: ${({ collapsed }) => !collapsed && "rotate(180deg)"};
          transition: 500ms ease-in-out;
        }
      }
    }
  }
  h3 {
    font: ${({ theme }) => theme.fonts.montserrat.heading};
    margin: 0;
    font-weight: 600 !important;
    color: ${({ theme }) => theme.colors.primary};
  }
  & > div {
    max-height: 20px;
    min-height: 20px;
    margin: 4px;

    & > div {
      max-height: 20px;
      min-height: 20px;
      margin: 4px;
      font-size: 4px;
    }
  }
  svg {
    cursor: pointer;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    align-items: flex-start;
    & > button {
        width: 100%;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    &>button{
      flex-direction: column;
      gap: 12px;
    }
  }
`;

export const ValuesGroup = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  max-width: 720px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    grid-template-columns: 1fr;
  }
`;

export const Value = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 720px;
  p {
    margin: 0;
    font: ${({ theme }) => theme.fonts.montserrat.regular};
    font-weight: 600 !important;
    &:first-of-type {
      color: ${({ theme }) => theme.colors.primaryOpaque};
    }
    &:last-of-type {
      color: ${({ theme }) => theme.colors.primary};
      padding: 0;
      opacity: 0.75;
      display: flex;
      align-items: center;

      svg {
        &:nth-of-type(odd) {
          margin-right: ${({ theme }) => theme.margins[3]};
        }
        &:nth-of-type(2) {
          margin: 0 ${({ theme }) => theme.margins[6]};
        }
      }
    }
  }
`;

export const SituationInformationContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  & > ${Value} {
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 3;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    grid-template-columns: 1fr;
  }
`;

export const CategorySeparator = styled.div`
  width: 80%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.border};
  grid-column-start: 1;
  grid-column-end: 3;
`;

export const ErrorsList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: ${({ theme }) => theme.margins[8]};
  & > li {
    font: ${({ theme }) => theme.fonts.montserrat.small};
    font-weight: 600 !important;
    color: ${({ theme }) => theme.colors.error};
    margin-bottom: ${({ theme }) => theme.margins[2]};
    position: relative;
    padding-left: ${({ theme }) => theme.paddings[4]};
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 6px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.error};
      display: block;
    }
  }
`;

export const ErrorFetchingContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid ${({ theme }) => theme.colors.error};
  align-items: center;
  border-radius: 8px;
  padding: ${({ theme }) => theme.paddings[4]};
  gap: 16px;
  height: 100%;
  width: 100%;
  margin: 0;
  svg {
    width: 48px;
    height: 48px;
  }
  p {
    font: ${({ theme }) => theme.fonts.montserrat.small};
    font-weight: 600 !important;
    color: ${({ theme }) => theme.colors.error};
    width: 80%;
    text-align: center;
    margin: 0 !important;
    &:last-of-type {
      padding: ${({ theme }) => theme.paddings[3]}
        ${({ theme }) => theme.paddings[4]};
      width: 80%;
      border-bottom: 2px solid ${({ theme }) => theme.colors.error};
      cursor: pointer;
      max-width: 180px;
      transition: 0.3s ease-in-out;
      &:hover {
        background-color: ${({ theme }) => theme.colors.error};
        color: ${({ theme }) => theme.colors.textOnPrimary};
        border-radius: 4px;
      }
    }
  }
`;
