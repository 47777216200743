import styled, { css } from "styled-components";

const sidebarWidth = "310px";
const sidebarWidthCollapsed = "128px";

export const SidebarWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  max-width: ${({ isSidebarOpen }) =>
    isSidebarOpen ? sidebarWidth : sidebarWidthCollapsed};
  width: 100%;
  height: 100%;
  transition: all 0.4s ease-in-out;
  z-index: 10000;
  height: 128px;
  /* background: rgba(0, 0, 0, 0.15); */
  & > div {
    &:nth-of-type(1) {
      position: relative;
      z-index: 10000;
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: unset;
  }
`;

export const NoADBResult = styled.p`
  font: ${({ theme }) => theme.fonts.montserrat.regular};
  font-weight: 600 !important;
  color: ${({ theme }) => theme.colors.primaryOpaque};

  text-align: center;
  width: 80%;
  margin: ${({ theme }) => theme.margins[3]} auto;
`;

export const SidebarLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 24px;
  svg {
    width: 72px;
    height: 72px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0;
    svg {
      height: 64px;
      width: 64px;
    }
  }
`;

export const SidebarUtilsBottom = styled.div`
  margin-top: auto;
  padding-bottom: 24px;
`;

export const SidebarToggle = styled.div`
  position: absolute;
  top: 0;
  left: ${() => sidebarWidthCollapsed};
  padding: 12px 12px 12px 16px;
  box-shadow: ${({ theme }) => theme.shadows.dark};
  background: #fff;
  transition: all 0.4s ease-in-out;
  z-index: 9999;
  border-radius: 0 0 8px 0;
  cursor: pointer;
  svg {
    width: 24px;
    height: 24px;
    transition: all 0.4s ease-in-out;
    transform: rotate(-90deg);
  }
  span {
    display: none;
  }
  ${({ isSidebarOpen }) =>
    isSidebarOpen &&
    css`
      left: ${() => sidebarWidth};
      svg {
        transform: rotate(90deg);
      }
    `}
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    z-index: 10001;
    left: unset;
    right: 32px;
    top: 32px;
    box-shadow: none;
    background: none;
    svg {
      display: none;
    }
    span {
      display: block;
      width: 24px;
      height: 3px;
      border-radius: 8px;
      background: ${({ theme }) => theme.colors.primary};
      position: relative;
      transition: all 0.4s ease-in-out;
      &::before {
        content: "";
        position: absolute;
        top: -8px;
        left: 0;
        width: 24px;
        height: 3px;
        border-radius: 8px;
        background: ${({ theme }) => theme.colors.primary};
        transition: all 0.4s ease-in-out;
      }
      &::after {
        content: "";
        position: absolute;
        top: 8px;
        left: 0;
        width: 24px;
        height: 3px;
        border-radius: 8px;
        background: ${({ theme }) => theme.colors.primary};
        transition: all 0.4s ease-in-out;
      }
    }
    ${({ isSidebarOpen }) =>
      isSidebarOpen &&
      css`
        span {
          background: transparent;
          &::before {
            top: 0;
            transform: rotate(45deg);
          }
          &::after {
            top: 0;
            transform: rotate(-45deg);
          }
        }
      `}
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    right: 16px;
  }
`;

export const SidebarMain = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SidebarUtil = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;

  cursor: pointer;
  width: ${({ isIcon }) => isIcon && "100%"};
  & > div {
    display: flex;
    align-items: center;
    gap: 16px;
    padding-left: 24px;
    &:first-of-type {
      svg:nth-of-type(2) {
        width: 20px;
        height: 20px;
        transform: scale(0);
        path {
          stroke: ${({ theme }) => theme.colors.textOnPrimary};
          stroke-width: 2px;
          fill: unset !important;
        }
      }
    }
  }
  &,
  & * {
    transition: 400ms ease-in-out;
  }
  p {
    font: ${({ theme }) => theme.fonts.montserrat.regular};
    font-weight: 600 !important;
    color: ${({ theme }) => theme.colors.primary};
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  svg {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
  }
  & > svg {
    height: 24px;
    width: 100%;
    max-width: 0;
  }
  input {
    font: ${({ theme }) => theme.fonts.montserrat.regular};
    font-weight: 600 !important;
    color: ${({ theme }) => theme.colors.primary};
  }
  ${({ isSidebarOpen }) =>
    isSidebarOpen &&
    css`
      padding: 16px 32px;
      p {
        max-width: 100%;
      }
      & > div {
        padding-left: 0;
        &:first-of-type {
          svg:nth-of-type(2) {
            transform: scale(1);
            path {
              stroke: ${({ theme }) => theme.colors.primary};
            }
          }
        }
      }
      & > svg {
        max-width: 24px;
      }
    `}
  ${({ theme, isActive, isInput }) =>
    isActive &&
    css`
      background: ${theme.colors.primary};
      color: ${theme.colors.textOnPrimary};
      input {
        color: ${theme.colors.textOnPrimary} !important;
      }
      path {
        fill: ${theme.colors.textOnPrimary};
      }
      p {
        color: ${theme.colors.textOnPrimary};
      }
      ${isInput &&
      css`
        & > div > div {
          border-bottom: 1px solid ${theme.colors.textOnPrimary};
        }
        & > div {
          &:nth-of-type(1) {
            & > svg:nth-of-type(2) {
              path {
                stroke: ${theme.colors.textOnPrimary};
              }
            }
          }
        }
      `}
      & > svg {
        transform: rotate(180deg);
        path {
          stroke: ${theme.colors.textOnPrimary};
          fill: unset;
        }
      }
    `}
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0;
    background: unset !important;
    width: 100%;
    & > div {
      padding-left: 0 !important;
      padding: ${({ theme }) => theme.paddings[3]}
        ${({ theme }) => theme.paddings[5]}!important;
      width: 100%;
      border-radius: 4px !important;
    }

    ${({ isSidebarOpen }) =>
      !isSidebarOpen &&
      css`
        p {
          display: none;
        }
      `};
    ${({ isActive }) =>
      isActive &&
      css`
        & > div {
          background: ${({ theme }) => theme.colors.primary};
          border-radius: 16px;
        }
      `}
  }
`;

export const ADBSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  & > div {
    &:first-of-type {
      width: 100%;
    }
    &:nth-of-type(2) {
      max-height: ${({ isActive }) => (isActive ? "280px" : "0")};
      transition: 0.4s ease-in-out;
      overflow-y: auto;
      width: 100%;
      ul {
        background: ${({ theme }) => theme.colors.searchBackground};
      }
      ul li {
        padding: 16px 24px 16px 48px;
        cursor: pointer;
        font: ${({ theme }) => theme.fonts.montserrat.regular};
        font-weight: 600 !important;
        color: ${({ theme }) => theme.colors.primary};
        &:hover {
          background: ${({ theme }) => theme.colors.disabled};
        }
      }
      &,
      & * {
        transition: 0.4s ease-in-out;
      }
      ${({ isLoading }) =>
        isLoading &&
        css`
          padding: 16px 0;
        `}
    }
  }
`;

export const SidebarContainer = styled.div`
  max-width: ${({ isSidebarOpen }) =>
    isSidebarOpen ? sidebarWidth : sidebarWidthCollapsed};
  width: 100%;
  background: #fff;
  box-shadow: ${({ theme }) => theme.shadows.dark};
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  transition: all 0.4s ease-in-out;
  display: flex;
  flex-direction: column;
  & > div {
    &:nth-of-type(2) {
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 3rem;
      overflow-y: auto;
      scrollbar-width: none;
      -ms-overflow-style: none;
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: unset;
    width: 100%;
    max-height: ${({ isSidebarOpen }) => (isSidebarOpen ? "100%" : "96px")};
    gap: 32px;
    padding: 0 ${({ theme }) => theme.paddings[8]};

    & > div {
      &:nth-of-type(1) {
        margin-right: auto;
        padding: ${({ theme }) => theme.paddings[4]};
      }
      &:nth-of-type(2) {
        & > div {
          &:first-of-type {
            gap: 8px;
            & > * {
              opacity: ${({ isSidebarOpen }) => (isSidebarOpen ? 1 : 0)};
              transition: all 0.4s ease-in-out;
            }
            & > div {
              & > div {
                & > div {
                  & > div {
                    max-width: unset;
                  }
                  svg:nth-of-type(2) {
                    width: 24px;
                    height: 24px;
                  }
                }
              }
            }
          }
          &:last-of-type {
            gap: 8px;
            & > * {
              opacity: ${({ isSidebarOpen }) => (isSidebarOpen ? 1 : 0)};
              transition: all 0.4s ease-in-out;
            }
          }
        }
      }
    }
    overflow: hidden;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 0 ${({ theme }) => theme.paddings[4]};
  }
`;

export const SupportWrapper = styled.div`
  position: relative;
  & > div {
    &:nth-of-type(1) {
      position: fixed;
      top: ${({ top }) => `${top}px`};
      transform: translateX(16px) translateY(-16px);
      left: ${({ left }) => `${left}px`};
      background: ${({ theme }) => theme.colors.textOnPrimary};
      box-shadow: ${({ theme }) => theme.shadows.dark};
      padding: ${({ theme }) => theme.paddings[4]};
      opacity: 0;
      transition: all 0.4s ease-in-out;

      max-width: 0;
      white-space: nowrap;
      border-radius: 4px;
      ${({ isSidebarOpen }) =>
        isSidebarOpen &&
        css`
          transform: translateX(48px) translateY(-16px);
        `}
      p {
        max-width: unset;
        display: block;
        &:first-of-type {
          font: ${({ theme }) => theme.fonts.montserrat.highlight};
          margin-bottom: ${({ theme }) => theme.margins[2]};
        }
        span {
          opacity: 0.5;
        }
      }
    }
    &:nth-of-type(2) {
      display: flex;
      align-items: center;
      gap: 16px;
      width: 100%;
    }
  }
  &:hover,
  &:focus {
    & > div {
      &:nth-of-type(1) {
        opacity: 1;
        max-width: 320px;
      }
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: flex;
    flex-direction: column-reverse;
    gap: 0 !important;
    & > div {
      &:nth-of-type(1) {
        position: relative;
        transform: unset;
        left: unset;
        top: unset;
        width: 100%;
        max-height: 0;
        opacity: 0 !important;
        padding: 0;
        max-width: unset !important;
        margin-top: 0;
      }
    }
    &:hover,
    &:focus {
      & > div {
        &:nth-of-type(1) {
          max-height: 100%;
          opacity: 1 !important;
          padding: ${({ theme }) => theme.paddings[4]};
          width: 100%;
          margin-top: 0.75rem;
        }
      }
    }
  }
  @media screen and (max-width: 360px) {
    & > div {
      &:nth-of-type(1) {
        & > p {
          white-space: normal;
        }
      }
    }
  }
`;
