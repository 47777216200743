//  RevenusForm
//  Form wrapper for revenus, handles rendering revenus items
//  State:
//     - revenus: object containing the revenus to render
//     - isLoading: boolean to know if the form is loading or not
//     - blockForm: boolean to know if the form is blocked or not

import { Form } from "formik";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { RevenusContainer } from "pages/dossierLocation/forms/formsStyles";
import {
  selectCurrentDossierLocataire,
  selectCurrentDossierNumerique,
  selectDossierLocation,
} from "redux/dossierLocation/dossierLocationReducer";
import { getRevenusWithPieces } from "utils/helpers/dossierNumeriqueHelper";

import { LocalNavigation, Spinner } from "../../index";
import RevenuItemContainer from "../revenus/revenuItemContainer";
import { useFilesFormContext } from "utils/context/filesFormContext";

const Revenus = (props) => {
  const currentDossierNumerique = useSelector(selectCurrentDossierNumerique);
  const { dossierRevenus } = props;
  const { statut: statutLocataire } = useSelector(
    selectCurrentDossierLocataire
  );
  const { statut: statutLocation } = useSelector(selectDossierLocation);
  const { pieces } = currentDossierNumerique;
  const [revenus, setRevenus] = useState({});
  const { isSubmitting, handleSubmit, setFieldValue, values } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [blockForm, setBlockForm] = useState(false);
  const [ignoreAvisImpot, setIgnoreAvisImpot] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { forceBlock } = useFilesFormContext();

  const handleIgnoreAvisImpot = (ignoreAvisImpot) => {
    setIgnoreAvisImpot(ignoreAvisImpot);
    setFieldValue("negligeRevenuFiscal", ignoreAvisImpot);

    if (ignoreAvisImpot && !values.REVENU_FISCAL_REFERENCE) {
      setFieldValue("REVENU_FISCAL_REFERENCE", 0);
    }
  };

  useEffect(() => {
    if (forceBlock || isSubmitting) {
      setBlockForm(true);
    } else {
      setBlockForm(false);
    }
  }, [statutLocataire, statutLocation, isSubmitting]);

  useEffect(() => {
    try {
      const { revenus, negligeRevenuFiscal } = dossierRevenus;

      setIsLoading(true);
      // maps the revenus with the pieces
      const pageRevenus = getRevenusWithPieces(revenus, pieces);
      setRevenus(pageRevenus);
      setIgnoreAvisImpot(negligeRevenuFiscal);
    } catch (error) {
      toast.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [dossierRevenus, pieces]);

  const handleRevenusSubmit = (e) => {
    e.preventDefault();
    if (blockForm) {
      navigate(`${location.pathname}?category=synthese`);
    } else {
      handleSubmit(e);
    }
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <Form>
        <div>
          <RevenusContainer isSubmitting={isSubmitting}>
            <div>
              <h1>Vérification de la capacité locative</h1>
              <p>
                Afin de procéder à la vérification complète du dossier MERCI de
                compléter les informations suivantes
              </p>
            </div>
            <div>
              {revenus &&
                Object.keys(revenus).map((key, index) => (
                  <RevenuItemContainer
                    key={index}
                    formikProps={props}
                    revenuItem={revenus[key]}
                    blocked={blockForm}
                    avisAcknowledged={ignoreAvisImpot}
                    setAvisAcknowledged={handleIgnoreAvisImpot}
                    dossierNumeriqueId={currentDossierNumerique?.id}
                  />
                ))}
            </div>
            {isSubmitting && <Spinner />}
          </RevenusContainer>
          <LocalNavigation
            submitAction={handleRevenusSubmit}
            isSubmitting={isSubmitting}
            linkPrev={`${location.pathname}?category=pieces`}
          />
        </div>
      </Form>
    </>
  );
};
export default Revenus;
