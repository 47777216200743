import axios from "axios";
import keycloak from "../../keycloak";

const API_URL = process.env.REACT_APP_API_URL;

let requestAbortController = new AbortController();

export const axiosInstance = axios.create({
  baseURL: API_URL,
  signal: requestAbortController.signal,
});

export const abortRequestAndResetController = () => {
  requestAbortController.abort();
  requestAbortController = new AbortController();
  axiosInstance.defaults.signal = requestAbortController.signal;
};

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${keycloak.token}`;
    return config;
  },
  (err) => {
    if (err.response.status === 401) {
      keycloak.logout({ redirectUri: window.location.origin });
    }
    return Promise.reject(err);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.code === "ERR_CANCELED") {
      return Promise.reject(error);
    }
    if (error.response?.status === 401) {
      keycloak.logout({ redirectUri: window.location.origin });
    }
    return Promise.reject(error);
  }
);

export const axiosPublicInstance = axios.create({
  baseURL: API_URL,
  signal: requestAbortController.signal,
});

export const getToken = () => {
  return keycloak.token;
};
