import { useKeycloak } from "@react-keycloak/web";
import { useSelector } from "react-redux";
import { selectActiveUser } from "redux/user/userReducer";
import Spinner from "../../spinner/spinner";
import { useNavigate } from "react-router";
import { hasOneOfRoles } from "utils/security/keycloakUtils";
import { useEffect, useState } from "react";
import {
  TYPE_ADB,
  TYPE_COURTIER,
  TYPE_LOQK,
  TYPE_PARTICULIER_BAILLEUR,
  TYPE_PARTICULIER_CANDIDAT,
} from "utils/constantes";

const IsAuthWrapper = ({ children, roles, fallbackLink = "/non-autorise" }) => {
  const { keycloak, initialized } = useKeycloak();
  const navigate = useNavigate();
  
  const utilisateur = useSelector(selectActiveUser);
  const [authorize, setAuthorize] = useState(false);
  

  const redirectUserDashBoard = () => {
    if (!utilisateur) {
      return;
    } else if (window.location.pathname === "/dashboard") {
      console.log("redirectUserDashBoard", utilisateur);
      if (
        (utilisateur.typeCompte === TYPE_PARTICULIER_CANDIDAT ||
          utilisateur.typeCompte === TYPE_PARTICULIER_BAILLEUR) &&
        !utilisateur.accepteCGU
      ) {
        navigate("/consentement");
      } else if (utilisateur && utilisateur.typeCompte === TYPE_ADB) {
        navigate("/candidate/dashboard");
      } else if (utilisateur && utilisateur.typeCompte === TYPE_LOQK) {
        navigate("/monespace/dashboard");
      } else if (utilisateur && utilisateur.typeCompte === TYPE_COURTIER) {
        navigate("/monespace/dashboard");
      } else if (
        utilisateur &&
        utilisateur.typeCompte === TYPE_PARTICULIER_CANDIDAT
      ) {
        navigate("/demande-candidature/dashboard/particular");
      } else if (
        utilisateur &&
        utilisateur.typeCompte === TYPE_PARTICULIER_BAILLEUR
      ) {
        navigate("/bailleur/dashboard");
      }
    } 
  }


  useEffect(() => {
    if (initialized && keycloak && !keycloak.authenticated) {
      keycloak.login({
        redirectUri: window.location.origin + "/dashboard",
      });
    }
    if (initialized && keycloak.authenticated && utilisateur && roles) {
      const contains = hasOneOfRoles(keycloak, roles);
      if (!contains) {
        console.log("NOT AUTHORIZED - ROLE REQUIRED", roles);
        navigate(fallbackLink);
      } else {
        setAuthorize(true);
        redirectUserDashBoard();
      }
      // cas of no constrainte on the roles => page without restriction => should be authenticated
    } else if (initialized && keycloak.authenticated && utilisateur && !roles) {
      setAuthorize(true);
      redirectUserDashBoard();
    } else if (initialized && !keycloak.authenticated) {
      keycloak.login();
    }
  }, [keycloak, initialized, utilisateur]);
  

  return authorize ? children : <Spinner marginY="172px" />;
};

export default IsAuthWrapper;
