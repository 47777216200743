//  ConfirmationPopupContent
//  Component used to display the content of the confirmation popup
//  Props:
//     - handleConfirmClick: function, the function to call when the user confirms
//     - handleCancelClick: function, the function to call when the user cancels
//     - confirmTitle: string, the message to display to the user
//     - confirmMessage: string, the prompt text to display to the user
//     - confirmButtonText: string, the text to display on the confirm button
//     - cancelButtonText: string, the text to display on the cancel button
//     - theme: object, the theme object
//     - hasPromptRequest: boolean, whether or not the popup has a request to input a text
//  State:
//     - prompMessage: string, the message entered by the user in the prompt request, max 250 characters, defaults to ""
//  Functions:
//     - handleSubmit: function to call when the user submits the form (confirming the action) and passes the prompt message to the handleConfirmClick function

import { useState } from "react";
import { ReactComponent as LogoIcon } from "assets//icons/logo.svg";
import {
  ConfirmationButtons,
  ConfirmationPopupContainer,
  ConfirmationPromptRequest,
} from "./confirmationStyles";

const ConfirmationPopupContent = ({
  handleConfirmClick = () => {},
  handleCancelClick = () => {},
  confirmTitle,
  confirmMessage,
  confirmButtonText,
  cancelButtonText,
  theme,
  hasPromptRequest,
  isSubmitting,
}) => {
  const [prompMessage, setPromptMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    handleConfirmClick(prompMessage);
  };
  return (
    <ConfirmationPopupContainer theme={theme} onSubmit={handleSubmit}>
      <LogoIcon />
      <h1>{confirmTitle}</h1>
      <p>{confirmMessage}</p>
      {
        // If the confirmation popup has a prompt request, we render the input field
        hasPromptRequest && (
          <ConfirmationPromptRequest
            hasError={false}
            readOnly={false}
            disabled={false}
            theme={theme}
          >
            <div>
              <label>Raison</label>
              <span>{prompMessage.length}/250</span>
            </div>

            <div>
              <textarea
                type="text"
                placeholder="Raison"
                name="prompt"
                id="prompt"
                required
                onChange={(e) => {
                  // limit to 250 characters
                  if (e.target.value.length <= 250) {
                    setPromptMessage(e.target.value);
                  }
                }}
                value={prompMessage}
              />
            </div>
          </ConfirmationPromptRequest>
        )
      }
      <ConfirmationButtons theme={theme}>
        <button type="submit" onClick={handleCancelClick}>
          {cancelButtonText}
        </button>
        <button
          type="submit"
          disabled={isSubmitting}
          onClick={
            !hasPromptRequest
              ? (e) => {
                  e.preventDefault();
                  handleConfirmClick(e);
                }
              : null
          }
        >
          {confirmButtonText}
        </button>
      </ConfirmationButtons>
    </ConfirmationPopupContainer>
  );
};

export default ConfirmationPopupContent;
