import { withTheme } from "styled-components";
import { StyledButton } from "../../index";
import { LocalNavigationContainer } from "./localNavigationStyles";
import { ReactComponent as Chevron } from "assets/icons/chevron.svg";

const LocalNavigation = ({
  linkPrev,
  submitAction = () => {},
  isSubmitting,
}) => {
  return (
    <LocalNavigationContainer>
      {linkPrev === "#" ? (
        <div></div>
      ) : (
        <StyledButton
          isFlex
          type="linkPrev"
          themeSizeSelector={["montserrat", "regular"]}
          themeSpacingSelector={[3, 14]}
          link={linkPrev}
        />
      )}
      <StyledButton
        isFlex
        type="submit"
        themeSizeSelector={["montserrat", "regular"]}
        themeSpacingSelector={[3, 14]}
        action={submitAction}
        isSubmitting={isSubmitting}
      >
        Suivant
        <Chevron />
      </StyledButton>
    </LocalNavigationContainer>
  );
};

export default withTheme(LocalNavigation);
