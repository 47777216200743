//  FilesUniqueContainer
//  Component to display unique files (files with only one piece)
//  Props:
//     - piece: object, the piece to display

import AttachmentContainer from "components/attachmentContainer/attachmentContainer";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import { ReactComponent as Chevron } from "assets/icons/chevron.svg";
import {
  FileCategoryContainer,
  FileCategoryHeading,
  FileInfoMessage,
} from "pages/dossierLocation/forms/formsStyles";
import { AUTRES_DOCUMENTS } from "utils/constantes";
import { useEffect, useState } from "react";
import { useMemo } from "react";
import { getAttachmentCategoryTitle } from "../utils/pieceUtils";

const FileUniqueContainer = ({
  piece,
  dossierNumerique,
  getAttachmentFileTitle = () => {},
  documentActions,
  displayLabel = true,
}) => {
  // default collapse only if type equals autres documents
  const [collapse, setCollapse] = useState(piece.type === AUTRES_DOCUMENTS);
  const pieceLabel = useMemo(() => getAttachmentCategoryTitle(piece), [piece]);
  useEffect(() => {
    if (piece.type === AUTRES_DOCUMENTS) {
      setCollapse(true);
    } else {
      setCollapse(false);
    }
  }, [piece]);

  return (
    <FileCategoryContainer collapse={collapse}>
      <FileCategoryHeading showOptions={collapse}>
        <h3>{pieceLabel}</h3>
        {piece.type === AUTRES_DOCUMENTS && (
          <div>
            <Chevron
              onClick={() => {
                setCollapse(!collapse);
              }}
            />
          </div>
        )}
      </FileCategoryHeading>
      <div>
        <AttachmentContainer
          piece={piece}
          getAttachmentFileTitle={getAttachmentFileTitle}
          dossierNumeriqueId={dossierNumerique?.id}
          documentActions={documentActions}
          displayLabel={displayLabel}
        />
      </div>
    </FileCategoryContainer>
  );
};

export default FileUniqueContainer;
