import styled from "styled-components";

export const FlowItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 420px;
  overflow: visible;
  text-align: center;

  & > h2 {
    font: ${(props) => props.theme.fonts.montserrat.heading};
    font-weight: ${(props) => props.theme.fontWeights.semiBold} !important;
    margin: ${(props) => props.theme.margins[8]} 0 0;
    color: ${(props) => props.theme.colors.primary};
    display: flex;
    align-items: center;
    gap: 12px;
    white-space: nowrap;
    span {
      height: 32px;
      cursor: pointer;
    }
  }
  & > p {
    margin-top: ${(props) => props.theme.margins[4]};
    max-width: 420px;
    font: ${(props) => props.theme.fonts.montserrat.highlight};
    font-weight: ${(props) => props.theme.fontWeights.medium} !important;
    color: ${(props) => props.theme.colors.primaryOpaque};
    span {
      font-weight: ${(props) => props.theme.fontWeights.semiBold} !important;
      color: ${(props) => props.theme.colors.primary};
      strong {
        color: ${(props) => props.theme.colors.loqkOriginal};
        font-weight: ${(props) => props.theme.fontWeights.semiBold} !important;
      }
    }
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    & > p {
      width: 100%;
    }
  }
`;

export const OverlayContainer = styled.div`
  position: absolute;
  top: 48px;
  right: 0;
  width: 70%;
  height: 100%;
  z-index: ${(props) => (props.isHovering ? 1 : -1)};
  padding: ${(props) => props.theme.paddings[6]}
    ${(props) => props.theme.paddings[18]}
    ${(props) => props.theme.paddings[12]}
    ${(props) => props.theme.paddings[12]};
  background: #fff;
  border-radius: 8px;
  transition: 400ms ease-in-out;
  opacity: ${(props) => (props.isHovering ? 1 : 0)};
  transform: ${(props) =>
    props.isHovering ? "translateY(0)" : "translateY(40px)"};
  box-shadow: 3px 4px 8px rgba(0, 0, 0, 0.13),
    inset 3px 4px 12px rgba(0, 0, 0, 0.05);
  text-align: start;
  h2 {
    max-width: 360px;
    font: ${(props) => props.theme.fonts.montserrat.large};
    font-weight: ${(props) => props.theme.fontWeights.semiBold} !important;
    color: ${(props) => props.theme.colors.primary};

    margin-bottom: ${(props) => props.theme.margins[6]};
  }
  p {
    color: ${(props) => props.theme.colors.primary};
    opacity: 0.75;
    font: ${(props) => props.theme.fonts.montserrat.regular};

    &:nth-of-type(1) {
      margin-bottom: ${(props) => props.theme.margins[4]};
    }
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: 400px;
    top: 420px;
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    height: calc(100% - 420px);
    padding: ${(props) => props.theme.paddings[6]}
      ${(props) => props.theme.paddings[8]}
      ${(props) => props.theme.paddings[4]}
      ${(props) => props.theme.paddings[8]};
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    top: 440px;
    height: auto;
    text-align: center;

    h2 {
      font: ${(props) => props.theme.fonts.montserrat.highlight};
      margin-bottom: ${(props) => props.theme.margins[4]};
    }
    p {
      font: ${(props) => props.theme.fonts.montserrat.small};
    }
  }
`;
