export const APIService = async ({
  apiEndpoint = async () => {},
  params = [],
  payload = [],
  urlParams = [],
  onChange = () => {},
  onSuccess = () => {},
  onError = (error) => {},
  onErrorGeneral = () => {},
}) => {
  try {
    onChange(true);
    const response = await apiEndpoint(...urlParams, ...payload, ...params);

    if (response.status === 200) {
      onSuccess(response.data);
    } else {
      throw new Error(response.data);
    }
  } catch (error) {
    onError(error?.response?.data?.message);
    onErrorGeneral(error);
    console.log(error);
  } finally {
    onChange(false);
  }
};
