export const ROLE_ADMIN_LOQK = "ROLE_ADMIN_LOQK";
export const ROLE_LOQK = "ROLE_LOQK";
export const ROLE_ADB = "ROLE_ADB";
export const ROLE_COURTIER = "ROLE_COURTIER";
export const ROLE_ADMIN_COURTIER = "ROLE_ADMIN_COURTIER";
export const ROLE_ADMIN_ADB = "ROLE_ADMIN_ADB";

export const ROLE_READ_DEMANDE = "ROLE_READ_DEMANDE";
export const ROLE_IMPORTE_DEMANDE = "ROLE_IMPORTE_DEMANDE";
export const ROLE_REOPEN_DEMANDE = "ROLE_REOPEN_DEMANDE";
export const ROLE_WRITE_DEMANDE = "ROLE_WRITE_DEMANDE";
export const ROLE_CREATE_DEMANDE = "ROLE_CREATE_DEMANDE";

export const ROLE_PARTICULIER_CANDIDAT = "ROLE_PARTICULIER_CANDIDAT";
export const ROLE_PARTICULIER_BAILLEUR = "ROLE_PARTICULIER_BAILLEUR";
export const ROLE_PARTICULIER = "ROLE_PARTICULIER";


// ROLE_UNITAIRE
export const ROLE_VALIDATION_DOSSIER_LOCATION =
  "ROLE_VALIDATION_DOSSIER_LOCATION";
export const ROLE_REJET_DOSSIER_LOCATION = "ROLE_REJET_DOSSIER_LOCATION";

const hasOneOfRoles = (keycloak, roles) => {
  return roles.some((r) => {
    const realm = keycloak.hasRealmRole(r);
    const resource = keycloak.hasResourceRole(r);
    return realm || resource;
  });
};

const missingRoles = (keycloak, roles) => {
  let rolesMatch = roles.filter((r) => {
    const realm = keycloak.hasRealmRole(r);
    const resource = keycloak.hasResourceRole(r);
    return !realm && !resource;
  });
  return rolesMatch.length > 0;
};

export { hasOneOfRoles, missingRoles };
