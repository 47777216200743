import { useEffect, useState } from "react";
import { withTheme } from "styled-components";
import {
  ActiveCategory,
  CategoriesContainer,
  CategoryOptions,
  CustomNavigation,
  Separator,
} from "pages//dossierLocation/forms/formsStyles";

import { ReactComponent as Chevron } from "assets//icons/chevron.svg";

import {
  ComponentWrapper,
  FormsCategory,
  ReturnLink,
  StyledButton,
} from "components";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentDossierNumerique,
  selectDossierLocation,
  selectCurrentDossierRevenus,
  selectCurrentDossierLocataire,
} from "redux//dossierLocation/dossierLocationReducer";
import useOutsideClick from "../../hooks/useOutsideClick";
import { ROUVRIR_DOSSIER_PARTENAIRE } from "utils/saga/actions";
import { DossierLocationStatut, DossierNumeriqueStatut } from "utils/Statuts";

const FormsHeader = ({ activeCategory, user, setIsLoading, categories }) => {
  const [showFormsOptions, setShowFormsOptions] = useState(false);
  const dossierNumerique = useSelector(selectCurrentDossierNumerique);
  const currentDossierLocation = useSelector(selectDossierLocation);
  const dossierRevenus = useSelector(selectCurrentDossierRevenus);
  const dossierLocataire = useSelector(selectCurrentDossierLocataire);
  const dispatch = useDispatch();
  const outsideClickRef = useOutsideClick(() => {
    setShowFormsOptions(false);
  });

  const rouvrirDossier = () => {
    dispatch({
      type: ROUVRIR_DOSSIER_PARTENAIRE,
      onChange: setIsLoading,
    });
  };

  const getAllowNavigate = (category) => {
    if (
      dossierLocataire?.statut === DossierNumeriqueStatut.VALIDE ||
      dossierLocataire?.statut === DossierNumeriqueStatut.BLOQUE ||
      currentDossierLocation?.statut === DossierLocationStatut.VALIDE ||
      currentDossierLocation?.statut === DossierLocationStatut.INVALIDE
    ) {
      return true;
    }
    const allCategories = ["situation", "pieces", "revenus", "synthese"];
    const currentCategoryIndex = allCategories.indexOf(activeCategory);
    const categoryIndex = allCategories.indexOf(category);
    return categoryIndex <= currentCategoryIndex;
  };

  useEffect(() => {
    setShowFormsOptions(false);
  }, [activeCategory]);

  return (
    <>
      <ComponentWrapper isDashboard ignoreTopPadding>
        <CustomNavigation ignoreMargin="bottom">
          <ReturnLink to={"/dashboard"}>Retour Tableau de Bord</ReturnLink>
          {dossierNumerique &&
            dossierNumerique.statut === DossierNumeriqueStatut.INVALIDE &&
            currentDossierLocation.statut !== DossierLocationStatut.INVALIDE &&
            currentDossierLocation.statut !== DossierLocationStatut.VALIDE &&
            activeCategory === "pieces" && (
              <StyledButton
                type="status"
                typeCategory="error"
                text="Modifier les documents"
                action={rouvrirDossier}
                themeSizeSelector={["montserrat", "regular"]}
                themeSpacingSelector={[4, 12]}
              />
            )}
        </CustomNavigation>
      </ComponentWrapper>

      <CategoriesContainer showOptions={showFormsOptions} ref={outsideClickRef}>
        <ActiveCategory
          showOptions={showFormsOptions}
          onClick={() => setShowFormsOptions(!showFormsOptions)}
        >
          {["situation", "pieces", "revenus", "synthese"].map(
            (category, index) => {
              if (category === activeCategory) {
                return (
                  <FormsCategory
                    key={index}
                    index={index + 1}
                    active={1}
                    linkTarget={category}
                    category={
                      category.charAt(0).toUpperCase() +
                      category.substr(1).toLowerCase()
                    }
                    enabled={true}
                    allowNavigate={getAllowNavigate(category)}
                  />
                );
              }
              return undefined;
            }
          )}
          <Chevron />
        </ActiveCategory>
        <CategoryOptions showOptions={showFormsOptions}>
          {categories.map((category, index) => (
            <FormsCategory
              key={index}
              index={index + 1}
              active={activeCategory === category.target}
              linkTarget={category.target}
              category={category.category}
              enabled={category.enabled}
              allowNavigate={category.allowNavigate}
            />
          ))}
        </CategoryOptions>
      </CategoriesContainer>
    </>
  );
};

export default withTheme(FormsHeader);
