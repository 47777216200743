import React from "react";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

const withNavigate = (WrappedComponent) => {
  const HOC = (props) => {
    const navigate = useNavigate();
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    return (
      <WrappedComponent
        {...props}
        navigate={navigate}
        params={params}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
    );
  };

  return HOC;
};

export default withNavigate;
