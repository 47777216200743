//  FilesSalaire
//  Component used to display all the salary files for a given user
//  Props:
//     - fichesPaie: array, the salary files to display
//  State:
//     - files: array, the salary files sorted by alphabetical order

import { useState, useEffect } from "react";
import AttachmentContainer from "../../attachmentContainer/attachmentContainer";
import {
  FileCategoryContainer,
  FileCategoryHeading,
} from "pages/dossierLocation/forms/formsStyles";
import { useFilesFormContext } from "utils/context/filesFormContext";

const FilesSalaire = ({
  getAttachmentFileTitle = () => {},
  dossierNumerique,
  documentActions,
  displayLabel = true,
}) => {
  const [files, setFiles] = useState([]);
  const { fichesPaie } = useFilesFormContext();

  useEffect(() => {
    // Sort files by alphabetical order
    let filesSorted = fichesPaie.sort((a, b) => {
      if (a.type > b.type) return 1;
      if (a.type < b.type) return -1;
      return 0;
    });
    setFiles(filesSorted);
  }, [fichesPaie]);

  return (
    <FileCategoryContainer collapse={false}>
      <FileCategoryHeading>
        <h3>Bulletin de salaire</h3>
      </FileCategoryHeading>
      <div>
        {files &&
          files.map((piece, index) => (
            <AttachmentContainer
              key={index}
              piece={piece}
              getAttachmentFileTitle={getAttachmentFileTitle}
              dossierNumeriqueId={dossierNumerique?.id}
              documentActions={documentActions}
              displayLabel={displayLabel}
            />
          ))}
      </div>
    </FileCategoryContainer>
  );
};

export default FilesSalaire;
