//  AttachmentContainer
//  Component that takes care of getting a piece image for preview
//  when a document has been uploaded, otherwise it displays an element to upload a document
//  it also handles uploading a document to API and displaying an error while fetching if there is one.
//  By clicking on the preview image you can open a popup to preview the document pdf version in a popup
//  with page navigation and support for scaling
//  Props:
//     - piece: the piece object to display
//  State:
//     - hasImage: image url obtained from API in base64 format
//     - isLoading: boolean to display a spinner while fetching the image
//     - fetchError: boolean to display an error message if there is an error while fetching the image
//     - isDragging: boolean, to manage dragging state for border styling
//     - isFileBlocked: boolean to block the file upload and modify actions
//     - popupData: object containing data for displaying the document preview popup
//  Functions:
//     - fetchImage: function to fetch image from API for preview, resets loading and error states
//     - processDocumentUploadToAPI: function to upload a document to API
//     - processDocumentUpload: function to process the document before uploading it to API

import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import imageCompression from "browser-image-compression";

import { ReactComponent as Tooltip } from "assets//icons/info.svg";
import ErrorFetchingImage from "./errorFetchingImage/errorFetchingImage";
import DocumentPreview from "components/documents/documentPreview/documentPreview";
import DocumentUpload from "components/documents/documentUpload/documentUpload";
import Spinner from "../spinner/spinner";
import { DocumentPreviewPopup } from "../popups/documentPreviewPopup/documentPreviewPopup";
import RenderIf from "../layout/renderIf/renderIf";
import { AttachmentHeader } from "pages//dossierLocation/forms/formsStyles";
import { withTheme } from "styled-components";
import DragAndDropWrapper from "../layout/dragAndDropWrapper/dragAndDropWrapper";
import {
  AttachmentComponentWrapper,
  AttachmentContainerWrapper,
} from "./attachmentContainerStyles";
import { PieceNumeriqueStatut } from "utils/Statuts";
import { useDebouncedCallback } from "use-debounce";
import TooltipPopup from "components/tooltip/tooltip";

import { useFilesFormContext } from "utils/context/filesFormContext";
import getAttachmentTooltipByType from "modules/demandes/pages/demandeCandidate/attachment/demandeAttachmentPopupContent";

const AttachmentContainer = ({
  piece,
  shouldShowActions = true,
  displayLabel = true,
  uploadNotAllowed = false,
  onClickAction = false,
  dossierNumeriqueId,
  getAttachmentFileTitle = () => {},
  documentActions,
}) => {
  const [hasImage, setHasImage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isFileBlocked, setIsFileBlocked] = useState(false);
  const [fileTitle, setFileTitle] = useState();
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltip, setTooltip] = useState(undefined);
  const [popupData, setPopupData] = useState({
    documentInfo: piece,
    document: null,
    showPopup: false,
    isLoading: false,
  });

  const { handleGetDocument, forceBlock, setProcessRunning } =
    useFilesFormContext();

  useEffect(() => {
    let fileTitle = getAttachmentFileTitle(piece);
    if (fileTitle) {
      fileTitle = fileTitle.charAt(0).toUpperCase() + fileTitle.slice(1);
      setFileTitle(fileTitle);
    }
    setTooltip(getAttachmentTooltipByType(piece?.type));
  }, [piece]);

  useEffect(() => {
    const statut = piece?.statut;
    if (
      (statut &&
        statut !== PieceNumeriqueStatut.EN_COURS &&
        statut !== PieceNumeriqueStatut.REJETEE &&
        statut !== PieceNumeriqueStatut.A_DEPOSER) ||
      forceBlock
    ) {
      setIsFileBlocked(true);
    } else {
      setIsFileBlocked(false);
    }
  }, [piece?.statut, forceBlock]);

  // function to fetch image from API for preview, resets loading and error states
  const fetchImage = () => {
    if (!piece.id) return;
    setIsLoading(true);
    setFetchError(false);
    // dispatch action to fetch image
    handleGetDocument(dossierNumeriqueId, piece.id, true, {
      onChange: setIsLoading,
      onSuccess: (data) => {
        let blob = new Blob([data], { type: "image/png" });
        let url = URL.createObjectURL(blob);
        setHasImage(url);
        setFetchError(false);
      },
      onError: (error) => {
        if (error?.response?.status === 404) {
          setFetchError(false);
          setHasImage(false);
        } else {
          setFetchError(true);
          setHasImage(false);
          toast.error("Une erreur s'est produite lors du chargement de pièce", {
            toastId: "fetchError",
          });
        }
      },
    });
  };

  // function to upload image to API
  const processDocumentUploadToAPI = (file, filename) => {
    documentActions.handleUploadDocument({
      pieceId: piece.id,
      file,
      filename,
      onChange: (boolean) => setIsLoading(boolean),
      onSuccess: () => {
        toast.success("Chargement du document a été effectué avec succès", {
          toastId: "uploadSuccess",
        });

        fetchImage();
        if (popupData.showPopup) {
          setPopupData({
            ...popupData,
            isLoading: true,
          });
          openDocumentPreview(piece);
        }
      },
      onError: (message) => {
        setIsLoading(false);
        toast.error(
          message || "Une erreur s'est produite lors du chargement de piéce",
          {
            toastId: "uploadError",
          }
        );
      },
    });
  };

  // function to handle file upload, processes image for exif data and orientation if it exists
  // and then uploads it to API using processDocumentUploadToAPI function
  const processDocumentUpload = async (e) => {
    e.preventDefault();
    if (
      forceBlock ||
      isFileBlocked ||
      (uploadNotAllowed && !popupData.showPopup)
    ) {
      toast.error("Vous ne pouvez pas modifier ce dossier");
      return;
    }
    setIsLoading(true);
    ///
    let file = e.type === "drop" ? e.dataTransfer.files[0] : e.target.files[0];
    const type = file.type;
    const acceptableTypes = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "application/pdf",
    ];

    if (!acceptableTypes.includes(type)) {
      toast.error("Le type de fichier n'est pas accepté", {
        toastId: "uploadError",
      });
      setIsLoading(false);
      return;
    }

    if (file.type.includes("image")) {
      await handleImageUpload(file);
    } else {
      // if uploaded file is not an image, just upload it without processing
      processDocumentUploadToAPI(file, file.name);
    }
  };

  const handleImageUpload = async (imageFile) => {
    try {
      const orientation = await imageCompression.getExifOrientation(imageFile);

      const options = {
        maxSizeMB: 1,
        //maxWidthOrHeight: 800,
        useWebWorker: true,
        preserveExif: false,
        exifOrientation: orientation,
      };

      const compressedFile = await imageCompression(imageFile, options);
      processDocumentUploadToAPI(compressedFile, imageFile.name); // write your own logic
    } catch (error) {
      toast.error("Impossible de charger l'image");
      setIsLoading(false);
    }
  };

  const deleteDocument = () => {
    if (
      forceBlock ||
      isFileBlocked ||
      (uploadNotAllowed && popupData.showPopup)
    ) {
      toast.error(
        "Ce document est bloqué. Veuillez vérifier les données du formulaire dans la page synthèse",
        {
          toastId: "deleteDocumentBlocked",
        }
      );
      return;
    }
    setPopupData({
      ...popupData,
      showPopup: false,
    });
    documentActions.handleDeleteDocument({
      pieceId: piece.id,
      onChange: (boolean) => setIsLoading(boolean),
      onSuccess: () => {
        setHasImage(false);
        setPopupData({
          documentInfo: piece,
          document: null,
          showPopup: false,
          isLoading: false,
        });
      },
    });
  };

  const downloadDocument = () => {
    handleGetDocument(dossierNumeriqueId, piece.id, false, {
      onChange: setIsLoading,
      onSuccess: (data) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.setAttribute("download", `${piece.id}.pdf`);
        link.setAttribute("href", url);
        document.body.appendChild(link);
        link.click();
        link.remove();
      },
    });
  };

  useEffect(() => {
    const { id } = piece;
    setProcessRunning((current) => ({
      ...current,
      [id]: isLoading,
    }));
  }, [piece, isLoading]);

  useEffect(() => {
    if (piece && piece.id) {
      fetchImage();
    }
  }, [piece]);

  const debouncePopupClear = useDebouncedCallback(() => {
    setPopupData({
      documentInfo: piece,
      document: null,
      showPopup: false,
      isLoading: false,
    });
  }, 800);

  const openDocumentPreview = () => {
    handleGetDocument(dossierNumeriqueId, piece.id, false, {
      onChange: setIsLoading,
      onSuccess: (data) => {
        setPopupData({
          documentInfo: piece,
          document: data,
          showPopup: true,
          isLoading: false,
        });
      },
    });
  };

  return !piece ? (
    <Spinner />
  ) : (
    <>
      <RenderIf condition={tooltip}>
        <TooltipPopup
          showTooltip={showTooltip}
          onClose={() => setShowTooltip(false)}
          tooltipText={tooltip}
          tooltipHeader={fileTitle}
        />
      </RenderIf>
      <AttachmentComponentWrapper>
        <DocumentPreviewPopup
          documentRef={popupData.document}
          documentInfo={popupData.documentInfo}
          showPopup={popupData.showPopup}
          isLoading={popupData.isLoading}
          togglePopup={() => {
            setPopupData({
              ...popupData,
              showPopup: !popupData.showPopup,
            });
            debouncePopupClear();
          }}
          uploadAction={processDocumentUpload}
          deleteAction={deleteDocument}
          downloadAction={downloadDocument}
          isFileBlocked={isFileBlocked || forceBlock}
        />
        <RenderIf condition={displayLabel}>
          <AttachmentHeader>
            <p>{fileTitle}</p>
            <RenderIf condition={tooltip}>
              <Tooltip onClick={() => setShowTooltip(true)} />
            </RenderIf>
          </AttachmentHeader>
        </RenderIf>
        <AttachmentContainerWrapper
          hasImage={hasImage}
          isProcessing={isLoading}
          isDragging={isDragging}
          blocked={isFileBlocked}
          uploadNotAllowed={uploadNotAllowed}
          hasError={fetchError}
        >
          <RenderIf condition={isLoading}>
            <Spinner marginY={"56px"} message="Téléchargement" />
          </RenderIf>
          <RenderIf condition={fetchError}>
            <ErrorFetchingImage retryAction={fetchImage} />
          </RenderIf>
          <RenderIf condition={!isLoading && !fetchError}>
            <DragAndDropWrapper
              onDragAndDropHandler={(e) => processDocumentUpload(e)}
              isLoading={isLoading}
              blocked={isFileBlocked}
              statut={piece?.statut}
              updateDrag={setIsDragging}
              uploadNotAllowed={uploadNotAllowed}
              hasImage={hasImage}
              clickUploadNotAllowed={hasImage}
            >
              <RenderIf condition={!hasImage}>
                <DocumentUpload
                  uploadAction={(e) => {
                    if (onClickAction && uploadNotAllowed) {
                      onClickAction();
                    } else {
                      processDocumentUpload(e);
                    }
                  }}
                  id={piece?.id}
                  blocked={isFileBlocked}
                  uploadNotAllowed={uploadNotAllowed}
                />
              </RenderIf>
              <RenderIf condition={hasImage}>
                <DocumentPreview
                  uploadAction={(e) => processDocumentUpload(e)}
                  fetchSpinnerAction={(boolean) => setIsLoading(boolean)}
                  downloadDocument={downloadDocument}
                  deleteDocument={deleteDocument}
                  setPopupState={setPopupData}
                  openDocumentPreview={openDocumentPreview}
                  filesRef={piece}
                  imgPreviewUrl={hasImage}
                  errors={piece?.errors}
                  blocked={isFileBlocked}
                  shouldShowActions={shouldShowActions}
                />
              </RenderIf>
            </DragAndDropWrapper>
          </RenderIf>
        </AttachmentContainerWrapper>
      </AttachmentComponentWrapper>
    </>
  );
};

export default withTheme(AttachmentContainer);
