import styled from "styled-components";
import {
  AUTRES_DOCUMENTS,
  AVIS_IMPOSITION,
  BULLETIN_SALAIRE_M1,
  BULLETIN_SALAIRE_M2,
  BULLETIN_SALAIRE_M3,
  CARTE_IDENTITE,
  JUSTIF_LOCATAIRE,
  JUSTIF_PROPRIETAIRE,
  JUSTIF_PRO_CDD,
  JUSTIF_PRO_CDI,
  JUSTIF_PRO_CDI_TRIAL,
  JUSTIF_PRO_CTT,
  JUSTIF_PRO_ETUDIANT,
  JUSTIF_PRO_INDEPENDENT,
  JUSTIF_PRO_INTERMITTENT,
  JUSTIF_PRO_NO_ACTIVITY,
  JUSTIF_PRO_OTHER,
  JUSTIF_PRO_RETIRED,
  JUSTIF_TITRE_GRATUIT,
  PASSPORT,
  PENSION_RETRAIRE,
  TITRE_SEJOUR,
} from "utils/constantes";

const JustificatifContentWrapper = styled.div`
  p {
    margin-bottom: 0.5rem;
  }
  a {
    color: ${({ theme }) => theme.colors.dossierFacile};
    font-weight: 600;
  }
  ul {
    padding-left: 2.25rem;
    margin-bottom: 0.5rem;
  }
`;

const FootNote = styled.p`
  padding-top: 0.75rem;
  margin-top: 0.75rem;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  padding-left: 1rem;
  font-size: 0.8rem;
`;

const BothSidesNotify = () => {
  return <p>Veillez à ajouter votre pièce recto-verso.</p>;
};

const JustificatifTitreGratuitContent = () => {
  return (
    <JustificatifContentWrapper>
      <p>
        Merci d'ajouter ici <strong>une attestation sur l'honneur</strong>{" "}
        d'hébergement à titre gratuit de moins de 3 mois (nous vous recommandons
        d'utiliser le modèle d'attestation disponible sur le site{" "}
        <a
          href="https://www.service-public.fr/simulateur/calcul/AttestationHebergement"
          target="_blank"
          rel="noopener noreferrer"
        >
          service-public.fr
        </a>
        ).
      </p>
      <p>
        Conseil: n&apos;alourdissez pas inutilement votre dossier avec des
        documents annexes inutiles.
      </p>
    </JustificatifContentWrapper>
  );
};

const JustificatifLocataireContent = () => {
  return (
    <JustificatifContentWrapper>
      <p>
        J&apos;ajoute <strong>mes 3 dernières quittances de loyer</strong> ou{" "}
        <strong>une attestation de bon paiement des loyers</strong> de moins de
        3 mois.
      </p>
      <p>
        Conseil: n&apos;alourdissez pas inutilement votre dossier avec des
        documents annexes inutiles.
      </p>
    </JustificatifContentWrapper>
  );
};

const JustificatifProprietaireContent = () => {
  return (
    <JustificatifContentWrapper>
      <p>
        J&apos;ajoute un{" "}
        <strong>avis de taxe foncière de moins d&apos;un an.</strong>
      </p>
      <p>
        Conseil: n&apos;alourdissez pas inutilement votre dossier avec des
        documents annexes inutiles.
      </p>
    </JustificatifContentWrapper>
  );
};

const JustificatifProCDIContent = () => {
  return (
    <JustificatifContentWrapper>
      <p>
        Merci d&apos;ajouter ici votre <strong>contrat de travail</strong> en
        cours ou à venir. Il doit être <strong>complet</strong> (toutes les
        pages) et <strong>signé</strong> par vous et votre employeur.
      </p>
      <p>
        Conseil: n&apos;alourdissez pas inutilement votre dossier avec des
        documents annexes, le contrat complet suffit.
      </p>
      <p>
        Si vous ne disposez pas de votre contrat de travail, vous pouvez ajouter
        une <strong>attestation de votre employeur de moins de 3 mois.</strong>
      </p>
    </JustificatifContentWrapper>
  );
};

const JustificatifProCDDContent = () => {
  return (
    <JustificatifContentWrapper>
      <p>
        Merci d&apos;ajouter ici votre <strong>contrat de travail</strong>. Il
        doit être <strong>en cours ou à venir</strong> (les CDD terminés ne sont
        pas acceptés), <strong>complet</strong> (toutes les pages) et{" "}
        <strong>signé</strong> par vous et votre employeur.
      </p>
      <p>
        Si vous ne disposez pas de votre contrat de travail, vous pouvez ajouter
        une <strong>attestation de votre employeur de moins de 3 mois.</strong>
      </p>
      <p>
        Conseil: n&apos;alourdissez pas inutilement votre dossier avec des
        documents annexes, le contrat complet suffit.
      </p>
    </JustificatifContentWrapper>
  );
};

const JustificatifProEtudiantContent = () => {
  return (
    <JustificatifContentWrapper>
      <p>Merci d&apos;ajouter ici l&apos;un des documents suivants :</p>
      <ul>
        <li>
          votre <strong>carte d&apos;étudiant(e)</strong>
        </li>
        <li>
          votre <strong>certificat de scolarité</strong>
        </li>
        <li>
          une <strong>attestation d&apos;inscription Parcoursup</strong>
        </li>
      </ul>
      <p>
        Le document doit mentionner votre nom, le nom de l&apos;établissement et
        l&apos;année scolaire en cours ou à venir.
      </p>
    </JustificatifContentWrapper>
  );
};

const JustificatifProCTTContent = () => {
  return (
    <JustificatifContentWrapper>
      <p>
        Merci d&apos;ajouter ici votre <strong>contrat de travail</strong> en
        cours ou à venir. Il doit être <strong>complet</strong> (toutes les
        pages) et <strong>signé</strong> par vous et votre employeur.
      </p>
      <p>
        Si vous ne disposez pas de votre contrat de travail, vous pouvez ajouter
        une <strong>attestation de votre employeur</strong> précisant
        l&apos;emploi et la rémunération proposée, la date d&apos;entrée en
        fonction envisagée et la durée du contrat prévue.
      </p>
      <p>
        Conseil: vous pouvez également ajouter ici vos certificats de travail de
        ces 3 derniers mois ou plus si vous en disposez. Cela permettra à un
        propriétaire de s&apos;assurer que vos missions sont régulières et
        récurrentes.
      </p>
    </JustificatifContentWrapper>
  );
};

const JustificatifProRetiredContent = () => {
  return (
    <JustificatifContentWrapper>
      <p>Merci d&apos;ajouter ici l&apos;un des documents suivants :</p>
      <ul>
        <li>
          un <strong>bulletin de pension</strong> ou une{" "}
          <strong>attestation de paiement de bulletin de pension</strong> de
          moins de 2 ans
        </li>
        <li>
          votre <strong>titre de pension de retraite</strong>
        </li>
      </ul>
      <p>
        Conseil: le bulletin de pension est le document le plus adapté ici.
        N&apos;ajoutez votre avis d&apos;imposition que si vous n&apos;avez
        aucun autre document de la liste.
      </p>
    </JustificatifContentWrapper>
  );
};

const JustificatifProIndependentContent = () => {
  return (
    <diJustificatifContentWrapperv>
      <p>Merci d&apos;ajouter ici l&apos;un des documents suivants :</p>
      <ul>
        <li>
          votre <strong>carte professionnelle</strong> (profession libérale)
        </li>
        <li>
          un <strong>extrait K ou K bis⁽¹⁾</strong> de moins de 3 mois
        </li>
        <li>
          un <strong>avis de situation au répertoire SIRENE⁽²⁾</strong> de moins
          de 3 mois
        </li>
        <li>
          votre <strong>relevé de situation comptable⁽³⁾</strong>{" "}
          (auto-entrepreneur)
        </li>
        <li>
          une <strong>fiche d'immatriculation⁽⁴⁾</strong> au Registre National
          des Entreprises
        </li>
        <li>
          une <strong>attestation de ressources</strong> pour l&apos;année en
          cours délivré par un comptable
        </li>
      </ul>
    </diJustificatifContentWrapperv>
  );
};

const JustificatifProIntermittentContent = () => {
  return (
    <JustificatifContentWrapper>
      <p>
        Merci d&apos;ajouter ici un{" "}
        <strong>avis de situation Pôle emploi⁽¹⁾</strong> de moins de 3 mois,
        ainsi que votre <strong>contrat de travail en cours</strong> si vous en
        avez un.
      </p>
      <p>
        onseil : vous pouvez également ajouter ici vos contrats de ces 3
        derniers mois. Cela permettra à un propriétaire de s&apos;assurer que
        vos missions sont régulières et récurrentes. Attention toutefois à ne
        pas surcharger votre dossier : si vous avez 30 contrats d&apos;un jour
        ou deux, il vaut mieux ajouter un document récapitulatif plutôt que ces
        30 contrats.
      </p>
      <FootNote>
        ⁽¹⁾ découvrez{" "}
        <a
          href="https://www.pole-emploi.fr/candidat/vos-services-en-ligne/les-pas-a-pas-les-videos-qui-vou/comment-obtenir-une-attestation.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          comment obtenir votre avis de situation
        </a>{" "}
        sur le site de Pôle emploi.
      </FootNote>
    </JustificatifContentWrapper>
  );
};

const JustificatifProNoActivityContent = () => {
  return (
    <JustificatifContentWrapper>
      <p>Merci d&apos;ajouter ici l&apos;un des documents suivants :</p>
      <ul>
        <li>un avis de situation Pôle emploi⁽¹⁾ de moins de 3 mois</li>
        <li>une attestation d&apos;ouverture de droits à l&apos;ARE</li>
      </ul>
      <FootNote>
        ⁽¹⁾ découvrez{" "}
        <a
          href="https://www.pole-emploi.fr/candidat/vos-services-en-ligne/les-pas-a-pas-les-videos-qui-vou/comment-obtenir-une-attestation.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          comment obtenir votre avis de situation
        </a>{" "}
        sur le site de Pôle emploi.
      </FootNote>
    </JustificatifContentWrapper>
  );
};

const JustificatifProOtherContent = () => {
  return (
    <JustificatifContentWrapper>
      <p>
        Veuillez ajouter ici une <strong>attestation sur l&apos;honneur</strong>{" "}
        qui explique votre situation.
      </p>
    </JustificatifContentWrapper>
  );
};

const JustificatifResourcesBulletinContent = () => {
  return (
    <JustificatifContentWrapper>
      <p>
        Merci d&apos;ajouter ici votre{" "}
        <strong>bulletin de salaire du mois demandé.</strong>
      </p>
    </JustificatifContentWrapper>
  );
};

const JustificatifResourceRetraireContent = () => {
  return (
    <JustificatifContentWrapper>
      <p>
        Merci d&apos;ajouter ici un <strong>bulletin de pension</strong> de
        moins de 2 ans, une <strong>attestation de pension</strong> ou votre{" "}
        <strong>dernier avis d&apos;imposition.</strong>
      </p>
      <p>
        Conseil: si vous n’avez pas de justificatif à fournir ici, vous pouvez
        ajouter ici une <strong>attestation sur l&apos;honneur</strong> qui
        explique votre situation. Vous pouvez utiliser ce{" "}
        <a
          href="https://www.service-public.fr/simulateur/calcul/AttestationHonneur"
          target="_blank"
          rel="noopener noreferrer"
        >
          modèle d&apos;attestation sur l&apos;honneur
        </a>
        .
      </p>
    </JustificatifContentWrapper>
  );
};

const JustificatifResourceIndependentContent = () => {
  return (
    <JustificatifContentWrapper>
      <p>
        Merci d&apos;ajouter ici vos{" "}
        <strong>3 dernières déclarations mensuelles de recettes</strong>, un
        relevé de situation comptable ou une attestation de déclaration de
        chiffres d&apos;affaires de moins de 3 mois
      </p>
    </JustificatifContentWrapper>
  );
};

const JustificatifAvisImpositionContent = () => {
  return (
    <JustificatifContentWrapper>
      <p>
        Merci d&apos;ajouter ici votre{" "}
        <strong>avis d&apos;imposition le plus récent⁽¹⁾</strong>, que vous
        soyez imposable ou non. Attention à bien fournir toutes les pages du
        document.
      </p>
      <p>
        Note: les avis des années précédentes peuvent également être ajoutés si
        vous le souhaitez, mais ils ne sont pas suffisants.
      </p>
      <FootNote>
        <strong>⁽¹⁾</strong> les avis d&apos;imposition sont disponibles dans
        l&apos;espace particulier du site{" "}
        <a
          href="https://www.impots.gouv.fr/accueil"
          target="_blank"
          rel="noopener noreferrer"
        >
          impots.gouv.fr.
        </a>{" "}
        N&apos;hésitez pas à consulter{" "}
        <a
          href="https://www.impots.gouv.fr/particulier/questions/jai-perdu-mon-avis-dimpot-sur-le-revenu-puis-je-en-obtenir-une-copie"
          target="_blank"
          rel="noopener noreferrer"
        >
          cette page
        </a>{" "}
        pour plus d&apos;informations.
      </FootNote>
    </JustificatifContentWrapper>
  );
};

const getAttachmentTooltipByType = (type) => {
  switch (type) {
    case CARTE_IDENTITE:
    case TITRE_SEJOUR:
    case PASSPORT:
      return <BothSidesNotify />;
    case JUSTIF_LOCATAIRE:
      return <JustificatifLocataireContent />;
    case JUSTIF_PROPRIETAIRE:
      return <JustificatifProprietaireContent />;
    case JUSTIF_TITRE_GRATUIT:
      return <JustificatifTitreGratuitContent />;
    case JUSTIF_PRO_CDI:
    case JUSTIF_PRO_CDI_TRIAL:
      return <JustificatifProCDIContent />;
    case JUSTIF_PRO_CDD:
      return <JustificatifProCDDContent />;
    case JUSTIF_PRO_CTT:
      return <JustificatifProCTTContent />;
    case JUSTIF_PRO_RETIRED:
      return <JustificatifProRetiredContent />;
    case JUSTIF_PRO_INDEPENDENT:
      return <JustificatifProIndependentContent />;
    case JUSTIF_PRO_INTERMITTENT:
      return <JustificatifProIntermittentContent />;
    case JUSTIF_PRO_NO_ACTIVITY:
      return <JustificatifProNoActivityContent />;
    case JUSTIF_PRO_ETUDIANT:
      return <JustificatifProEtudiantContent />;
    case JUSTIF_PRO_OTHER:
    case AUTRES_DOCUMENTS:
      return <JustificatifProOtherContent />;
    case BULLETIN_SALAIRE_M1:
    case BULLETIN_SALAIRE_M2:
    case BULLETIN_SALAIRE_M3:
      return <JustificatifResourcesBulletinContent />;
    case PENSION_RETRAIRE:
      return <JustificatifResourceRetraireContent />;
    case AVIS_IMPOSITION:
      return <JustificatifAvisImpositionContent />;

    default:
      break;
  }
};

export default getAttachmentTooltipByType;
