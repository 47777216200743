import {
  STATUT_ARTISAN,
  STATUT_ASSISTANTE_MATERNELLE,
  STATUT_AUTRE,
  STATUT_CDD,
  STATUT_CDI_CONFIRME,
  STATUT_CDI_PERIODE_ESSAI,
  STATUT_COMMERCANT,
  STATUT_CREATEUR_ENTREPRISE,
  STATUT_DIPLOMATE,
  STATUT_GERANT_MAJORITAIRE,
  STATUT_INTERIMAIRE,
  STATUT_INTERMITTENT,
  STATUT_INVALIDE,
  STATUT_NON_TITULAIRE,
  STATUT_PERSONNEL_AMBASSADE,
  STATUT_PROFESSION_LIBERALE,
  STATUT_RENTIER,
  STATUT_TITULAIRE,
  SITUATION_AUTRES,
  SITUATION_ETUDIANT,
  SITUATION_RECHERCHE_EMPLOI,
  SITUATION_RETRAITE,
  SITUATION_SALARIE_PRIVE,
  SITUATION_SALARIE_PUBLIC,
  SITUATION_TRAVAILLEUR_NON_SALARIE,
  STATUT_ETUDIANT,
} from "./situationStatutProConstantes";
import { PieceNumeriqueStatut } from "./Statuts";

// Types utilisateurs
const TYPE_ADB = "ADB";
const TYPE_LOQK = "LOQK";
const TYPE_COURTIER = "COURTIER";
const TYPE_PARTICULIER_CANDIDAT = "PARTICULIER_CANDIDAT";
const TYPE_PARTICULIER_BAILLEUR = "PARTICULIER_BAILLEUR";

const DATE_API_FORMAT = "yyyy-MM-DD";
const DATE_FRONT_FORMAT = "DD/MM/yyyy";
const MONTH_YEAR_FORMAT = "MMMM yyyy";

const CODE_NAF_REGEX = /^[0-9]{4}[a-z|A-Z]$/;
const DEPARTEMENT_REGEX = /^[0-9][a-b|A-B|0-9]$/;
const SIRET_REGEX = /^[0-9]{14}$/;
const EMAIL_REGEX = "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,3}$";
const TELEPHONE_REGEX = "^[0][1-9][0-9]{8}$";

const STATUT_LOCATAIRE_ENUM = [
  { key: "LOCATAIRE", value: "Locataire" },
  { key: "OCCUPANT_GRATUIT", value: "Occupant à titre gratuit" },
  { key: "PROPRIETAIRE", value: "Propriétaire" },
];

const DEMANDE_CANDIDATURE_INCOMPLETE = "INCOMPLETE";
const DEMANDE_CANDIDATURE_COMPLETE = "COMPLETE";
const DEMANDE_CANDIDATURE_FINALISEE = "FINALISEE";
const DEMANDE_CANDIDATURE_IMPORTEE = "IMPORTEE";

const DEMANDE_CANDIDATURE_STATUS = (typeCompte) => [
  {
    key: DEMANDE_CANDIDATURE_INCOMPLETE,
    value: "Incomplet",
  },
  {
    key: DEMANDE_CANDIDATURE_COMPLETE,
    value: typeCompte === TYPE_PARTICULIER_CANDIDAT ? "A finaliser" : "Complet",
  },
  {
    key: DEMANDE_CANDIDATURE_FINALISEE,
    value: "Finalisé",
  },
  {
    key: DEMANDE_CANDIDATURE_IMPORTEE,
    value: typeCompte === TYPE_PARTICULIER_CANDIDAT ? "Finalisé" : "Importé",
  },
];

const STATUT_LOCATAIRE_DEPUIS_ENUM = [
  { key: "MOINS_UN_AN", value: "Moins d'1 an" },
  { key: "UN_AN", value: "1 an" },
  { key: "DEUX_ANS", value: "2 ans" },
  { key: "TROIS_ANS", value: "3 ans" },
  { key: "QUATRE_ANS", value: "4 ans" },
  { key: "CINQ_ANS", value: "5 ans" },
  { key: "PLUS_CINQ_ANS", value: "Plus de 5 ans" },
];

const SITUATION_PERSO_ENUM = [
  { key: "CELIBATAIRE", value: "Célibataire" },
  { key: "EN_COUPLE", value: "En couple" },
  { key: "SEPARE", value: "Séparé(e)" },
  { key: "VEUF", value: "Veuf/Veuve" },
];
//SALARIE_PRIVE, SALARIE_PUBLIC, TRAVAILLEUR_NON_SALARIE, ETUDIANT, RETRAITE, RECHERCHE_EMPLOI, AUTRES
const SITUATION_PRO_ENUM = [
  { key: SITUATION_SALARIE_PRIVE, value: "Salarié privé" },
  { key: SITUATION_SALARIE_PUBLIC, value: "Salarié public" },
  { key: SITUATION_TRAVAILLEUR_NON_SALARIE, value: "Travailleur non salarié" },
  { key: SITUATION_ETUDIANT, value: "Etudiant" },
  { key: SITUATION_RETRAITE, value: "Retraité" },
  { key: SITUATION_RECHERCHE_EMPLOI, value: "Recherche d'emploi" },
  { key: SITUATION_AUTRES, value: "Autres" },
];

const NATURE_ENUM = [
  { key: "PRINCIPALE", value: "Principale" },
  { key: "PROFESSIONNELLE", value: "Professionnelle" },
  { key: "ADMIN_LOQK", value: "Admin LOQK" },
];

const PROFILE_ENUM = [
  {
    key: "ADMIN_LOQK",
    value: "Admin LOQK",
  },
  {
    key: "GESTIONNAIRE_LOQK",
    value: "Gestionnaire LOQK",
  },
  {
    key: "ADMIN_COURTIER",
    value: "Admin Courtier",
  },
  {
    key: "GESTIONNAIRE_COURTIER",
    value: "Gestionnaire Courtier",
  },
  {
    key: "ADMIN_ADB",
    value: "Admin ADB",
  },
  {
    key: "GESTIONNAIRE_ADB",
    value: "Gestionnaire ADB",
  },
  {
    key: "NEGOCIATEUR_ADB",
    value: "Négociateur ADB",
  },
];

const ALL_STATUT_PRO_ENUM = [
  STATUT_CDI_PERIODE_ESSAI,
  STATUT_CDI_CONFIRME,
  STATUT_CDD,
  STATUT_INTERIMAIRE,
  STATUT_INTERMITTENT,
  STATUT_ASSISTANTE_MATERNELLE,
  STATUT_TITULAIRE,
  STATUT_NON_TITULAIRE,
  STATUT_CREATEUR_ENTREPRISE,
  STATUT_GERANT_MAJORITAIRE,
  STATUT_ARTISAN,
  STATUT_COMMERCANT,
  STATUT_PROFESSION_LIBERALE,
  STATUT_INVALIDE,
  STATUT_DIPLOMATE,
  STATUT_PERSONNEL_AMBASSADE,
  STATUT_RENTIER,
  STATUT_AUTRE,
];

const STATUT_PRO_ENUM = [
  {
    key: SITUATION_SALARIE_PRIVE,
    values: [
      STATUT_CDI_PERIODE_ESSAI,
      STATUT_CDI_CONFIRME,
      STATUT_CDD,
      STATUT_INTERIMAIRE,
      STATUT_INTERMITTENT,
      STATUT_ASSISTANTE_MATERNELLE,
    ],
  },
  {
    key: SITUATION_SALARIE_PUBLIC,
    values: [STATUT_TITULAIRE, STATUT_NON_TITULAIRE],
  },
  {
    key: SITUATION_TRAVAILLEUR_NON_SALARIE,
    values: [
      STATUT_CREATEUR_ENTREPRISE,
      STATUT_GERANT_MAJORITAIRE,
      STATUT_ARTISAN,
      STATUT_COMMERCANT,
      STATUT_PROFESSION_LIBERALE,
    ],
  },
  { key: SITUATION_ETUDIANT, values: [STATUT_ETUDIANT] },
  {
    key: SITUATION_AUTRES,
    values: [
      STATUT_INVALIDE,
      STATUT_DIPLOMATE,
      STATUT_PERSONNEL_AMBASSADE,
      STATUT_RENTIER,
      STATUT_AUTRE,
    ],
  },
  {
    key: SITUATION_RETRAITE,
    values: [],
  },
  {
    key: SITUATION_RECHERCHE_EMPLOI,
    values: [],
  },
];

const QUALITE_ENUM = [
  { key: "MM", value: "Mme." },
  { key: "MR", value: "Mr." },
];

const TRAVAILLE_12_QUESTION_ENUM = [
  { key: "true", value: "Oui" },
  { key: "false", value: "Non" },
];

const STATUT_DOSSIER_LOCATION = [
  {
    key: "INCOMPLET",
    value: "Incomplet",
  },
  {
    key: "VALIDE",
    value: "Validé",
  },
  {
    key: "INVALIDE",
    value: "Non validé",
  },
];

const STATUT_DOSSIER_LOCATAIRE = [
  {
    key: "INCOMPLET",
    value: "Incomplet",
  },
  {
    key: "VALIDE",
    value: "Validé",
  },
  {
    key: "NON_VALIDE",
    value: "Non validé",
  },
];

const STATUT_DOSSIER_CANDIDAT = [
  {
    key: "INCOMPLET",
    value: "Incomplet",
  },
  {
    key: "COMPLET",
    value: "Complet",
  },
];

export const CARTE_IDENTITE = "CARTE_IDENTITE";
export const TITRE_SEJOUR = "TITRE_SEJOUR";
export const PASSPORT = "PASSPORT";

export const BULLETIN_SALAIRE_M1 = "BULLETIN_SALAIRE_M1";
export const BULLETIN_SALAIRE_M2 = "BULLETIN_SALAIRE_M2";
export const BULLETIN_SALAIRE_M3 = "BULLETIN_SALAIRE_M3";
export const PENSION_RETRAIRE = "PENSION_RETRAIRE";

export const CARTE_ETUDIANT = "CARTE_ETUDIANT";
export const CONTRAT_TRAVAIL = "CONTRAT_TRAVAIL";
export const AVIS_IMPOSITION = "AVIS_IMPOSITION";
export const AUTRES_DOCUMENTS = "AUTRES_DOCUMENTS";

export const PIECE_TAX = "TAX";
export const PIECE_RESIDENCY = "RESIDENCY";
export const PIECE_PROFESSIOANL = "PROFESSIONAL";
export const PIECE_OTHER = "NULL";
export const PIECE_FINANCIAL = "FINANCIAL";

export const JUSTIF_LOCATAIRE = "JUSTIFICATIF_LOCATAIRE";
export const JUSTIF_PROPRIETAIRE = "JUSTIFICATIF_PROPRIETAIRE";
export const JUSTIF_TITRE_GRATUIT = "JUSTIFICATIF_TITRE_GRATUIT";

export const JUSTIF_PRO_FILES = "JUSTIF_PRO";
export const JUSTIF_PRO_CDI = "JUSTIF_PRO_CDI";
export const JUSTIF_PRO_CDI_TRIAL = "JUSTIF_PRO_CDI_TRIAL";
export const JUSTIF_PRO_CDD = "JUSTIF_PRO_CDD";
export const JUSTIF_PRO_PUBLIC = "JUSTIF_PRO_PUBLIC";
export const JUSTIF_PRO_CTT = "JUSTIF_PRO_CTT";
export const JUSTIF_PRO_RETIRED = "JUSTIF_PRO_RETIRED";
export const JUSTIF_PRO_INDEPENDENT = "JUSTIF_PRO_INDEPENDENT";
export const JUSTIF_PRO_INTERMITTENT = "JUSTIF_PRO_INTERMITTENT";
export const JUSTIF_PRO_OTHER = "JUSTIF_PRO_OTHER";
export const JUSTIF_PRO_NO_ACTIVITY = "JUSTIF_PRO_NO_ACTIVITY";
export const JUSTIF_PRO_ETUDIANT = "JUSTIF_PRO_ETUDIANT";


export const JUSTIF_RESOURCES_INDEPENDANT = "JUSTIF_RESOURCES_INDEPENDANT";

const CARTE_IDENTITE_TYPE = {
  key: "CARTE_IDENTITE",
  value: "Carte d'identité",
};

const PASSPORT_TYPE = {
  key: "PASSPORT",
  value: "Passeport",
};

const TITRE_SEJOUR_TYPE = {
  key: "TITRE_SEJOUR",
  value: "Titre de séjour",
};

const PIECES_STATUT_ENUM = [
  {
    key: PieceNumeriqueStatut.VALIDEE,
    value: "Validé",
  },
  {
    key: PieceNumeriqueStatut.REJETEE,
    value: "Rejeté",
  },
  {
    key: PieceNumeriqueStatut.A_DEPOSER,
    value: "A Déposer",
  },
  {
    key: PieceNumeriqueStatut.EN_COURS,
    value: "En Cours",
  },
  {
    key: PieceNumeriqueStatut.INVALIDEE,
    value: "Invalidé",
  },
];

export const PIECES_IDENTITE_ENUM = [
  CARTE_IDENTITE_TYPE,
  PASSPORT_TYPE,
  TITRE_SEJOUR_TYPE,
];

export const PIECES_ENUM = [
  {
    key: TITRE_SEJOUR,
    value: "Titre de séjour",
  },
  {
    key: CARTE_IDENTITE,
    value: "Carte d'identité",
  },
  {
    key: PASSPORT,
    value: "Passeport",
  },
  {
    key: CARTE_ETUDIANT,
    value: "Carte d'étudiant",
  },
  {
    key: CONTRAT_TRAVAIL,
    value: "Contrat de travail",
  },
  {
    key: AVIS_IMPOSITION,
    value: "Avis d'imposition",
  },
  {
    key: BULLETIN_SALAIRE_M1,
    value: "M-1",
  },
  {
    key: BULLETIN_SALAIRE_M2,
    value: "M-2",
  },
  {
    key: BULLETIN_SALAIRE_M3,
    value: "M-3",
  },
  {
    key: AUTRES_DOCUMENTS,
    value: "Autres documents",
  },
  {
    key: JUSTIF_PRO_CDI,
    value: "Justificatif de situation",
  },
  {
    key: JUSTIF_PRO_CDI_TRIAL,
    value: "Justificatif de situation",
  },
  {
    key: JUSTIF_PRO_CDD,
    value: "Justificatif de situation",
  },
  {
    key: JUSTIF_PRO_PUBLIC,
    value: "Justificatif de situation",
  },
  {
    key: JUSTIF_PRO_CTT,
    value: "Justificatif de situation",
  },
  {
    key: JUSTIF_PRO_RETIRED,
    value: "Justificatif de situation",
  },
  {
    key: JUSTIF_PRO_INDEPENDENT,
    value: "Justificatif de situation",
  },
  {
    key: JUSTIF_PRO_INTERMITTENT,
    value: "Justificatif de situation",
  },
  {
    key: JUSTIF_PRO_OTHER,
    value: "Justificatif de situation",
  },
  {
    key: JUSTIF_PRO_NO_ACTIVITY,
    value: "Justificatif de situation",
  },
  {
    key: JUSTIF_PRO_ETUDIANT,
    value: "Justificatif d'étudiant",
  },
  {
    key: JUSTIF_LOCATAIRE,
    value: "Justificatifs de loyer",
  },
  {
    key: JUSTIF_PROPRIETAIRE,
    value: "Avis de taxe foncière",
  },
  {
    key: JUSTIF_TITRE_GRATUIT,
    value: "Attestation d'hébergement",
  },
  {
    key: PENSION_RETRAIRE,
    value: "Justificatif de retraite",
  },
  {
    key: JUSTIF_RESOURCES_INDEPENDANT,
    value: "Justificatif de ressources",
  }
];

export const REVENUS_ENUM = [
  {
    key: "SALAIRE_MENSUEL_M1",
    value: "Salaire Net Fiscal Mensuel M1",
  },
  {
    key: "CUMUL_ANNUEL_NET_M1",
    value: "Cumul Net Fiscal Mensuel M1",
  },
  {
    key: "SALAIRE_MENSUEL_M2",
    value: "Salaire Net Fiscal Mensuel M2",
  },
  {
    key: "CUMUL_ANNUEL_NET_M2",
    value: "Cumul Net Fiscal Mensuel M2",
  },
  {
    key: "SALAIRE_MENSUEL_M3",
    value: "Salaire Net Fiscal Mensuel M3",
  },
  {
    key: "CUMUL_ANNUEL_NET_M3",
    value: "Cumul Net Fiscal Mensuel M3",
  },
  {
    key: "SALAIRE_BRUT_ANNUEL",
    value: "Salaire Brut Annuel",
  },
  {
    key: "REVENU_FISCAL_REFERENCE",
    value: "Revenu Fiscal Référence",
  },
];

export const TYPE_VALIDATION_FORCE = "FORCE";
export const TYPE_VALIDATION_AUTO = "AUTO";

//CELIBATAIRE, EN_COUPLE, SEPARE, VEUF
export {
  TYPE_ADB,
  TYPE_LOQK,
  TYPE_COURTIER,
  TYPE_PARTICULIER_CANDIDAT,
  TYPE_PARTICULIER_BAILLEUR,
  DATE_API_FORMAT,
  MONTH_YEAR_FORMAT,
  STATUT_LOCATAIRE_ENUM,
  STATUT_LOCATAIRE_DEPUIS_ENUM,
  SITUATION_PERSO_ENUM,
  SITUATION_PRO_ENUM,
  STATUT_PRO_ENUM,
  QUALITE_ENUM,
  TRAVAILLE_12_QUESTION_ENUM,
  STATUT_DOSSIER_LOCATION,
  ALL_STATUT_PRO_ENUM,
  DATE_FRONT_FORMAT,
  CODE_NAF_REGEX,
  DEPARTEMENT_REGEX,
  SIRET_REGEX,
  EMAIL_REGEX,
  TELEPHONE_REGEX,
  CARTE_IDENTITE_TYPE,
  TITRE_SEJOUR_TYPE,
  PASSPORT_TYPE,
  NATURE_ENUM,
  PROFILE_ENUM,
  PIECES_STATUT_ENUM,
  DEMANDE_CANDIDATURE_STATUS,
  STATUT_DOSSIER_LOCATAIRE,
  STATUT_DOSSIER_CANDIDAT,
  DEMANDE_CANDIDATURE_INCOMPLETE,
  DEMANDE_CANDIDATURE_COMPLETE,
};
