import styled from "styled-components";

export const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: ${({ showPopup }) => (showPopup ? "0" : "200%")};
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.popupBackground};
  z-index: 10000;
  transition: 500ms ease-in-out;
`;

export const PopupContent = styled.div`
  padding: ${({ theme }) => theme.paddings[10]}
    ${({ theme }) => theme.paddings[12]};
  background: #fff;
  border-radius: 16px;
  position: absolute;
  top: 50%;
  left: ${({ showPopup }) => (showPopup ? "50%" : "200%")};
  max-height: 90vh;
  transform: translate(-50%, -50%);
  transition: 500ms cubic-bezier(0.99, 0, 0.45, 1);
  display: flex;
  flex-direction: column;
  height: ${({ heightConstraint }) => heightConstraint};

  min-width: ${({ minWidth }) => minWidth || "auto"};
  @media screen and (max-width: 1024px) {
    width: 75%;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    height: ${({ heightConstraint }) => heightConstraint};
    padding: ${({ theme }) => theme.paddings[8]}
      ${({ theme }) => theme.paddings[4]} ${({ theme }) => theme.paddings[8]};
    width: 100vw;
    max-height: 100vh;
    border-radius: 0;
    min-width: unset;
  }
`;

export const NoDataInDocument = styled.div`
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 ${({ theme }) => theme.paddings[24]};
  & > h3 {
    font: ${({ theme }) => theme.fonts.inter.highlight};
    color: ${({ theme }) => theme.colors.error};
    margin: ${({ theme }) => theme.margins[4]} 0;
    font-weight: 600 !important;
    width: 80%;
    text-align: center;
  }
  svg {
    width: 56px;
    height: 56px;
  }
`;

export const CloseIconContainer = styled.div`
  position: absolute;
  top: ${({ type }) => (type === "regular" ? "56px" : "24px")};
  right: ${({ type }) => (type === "regular" ? "40px" : "24px")};
  cursor: pointer;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    top: ${({ type }) => (type === "regular" ? "40px" : "24px")};
  }
`;

export const WarningContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  & > h3 {
    font: ${({ theme }) => theme.fonts.inter.heading};
    color: ${({ theme }) => theme.colors.error};
    margin: ${({ theme }) => theme.margins[4]} 0
      ${({ theme }) => theme.margins[8]};
  }
  & > p {
    font: ${({ theme }) => theme.fonts.inter.regular};
    font-weight: 500 !important;
    color: ${({ theme }) => theme.colors.primary};
    margin: 0 0 ${({ theme }) => theme.margins[14]};
    max-width: 342px;
    text-align: center;
  }
`;

export const PreviewContainer = styled.div`
  width: 612px;

  margin-top: ${({ theme }) => theme.margins[8]};
  height: calc(100% - ${({ theme }) => theme.margins[8]});
  & > div {
    &:first-of-type {
      height: 85%;
      @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
        height: 80%;
      }
    }
    &:nth-of-type(2) {
      grid-column: 1/2;
      grid-row: 7/8;
    }
    box-shadow: ${({ theme }) => theme.shadows.lightDark};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: ${({ theme }) => theme.margins[4]};
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    width: 100%;
  }
`;

export const ToggleArrow = styled.div`
  cursor: ${({ show }) => (show ? "pointer" : "default")};
  opacity: ${({ show }) => (show ? "1" : "0")};
  transition: 400ms ease-in-out;
  height: max-content !important;
  &:hover {
    opacity: ${({ show }) => (show ? "1" : "0")};
  }
`;

export const DocumentContainer = styled.div`
  flex-grow: 2;
  height: 100%;
  width: 100%;
  & > div {
    height: 100%;
    min-height: unset !important;
    & > div {
      display: block;
      width: 100%;
      height: 100%;
      min-width: unset !important;
      min-height: unset !important;
      overflow: auto;
      & > div {
        display: none;
      }
      & > canvas {
        width: 100% !important;
        object-fit: contain;
        height: auto !important;
        transform-origin: left top;
        transform: ${({ scale }) => `scale(${scale})`};
        @media screen and (max-width: ${({ theme }) =>
            theme.breakpoints.small}) {
          height: 100% !important;
        }
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-grow: unset;

    & > div {
      height: 100%;
      & > div {
        height: 100%;
      }
    }
  }
`;

export const DocumentHandlingActions = styled.div`
  margin-top: ${({ theme }) => theme.margins[6]};
  background: #fff;
  height: auto !important;
  box-shadow: none !important;
  max-height: ${({ enlarged }) => (!enlarged ? "96px" : "0")};
  overflow: hidden;
  transition: 400ms ease-in-out;
  position: relative;
  z-index: 1000;

  & > div {
    &:first-of-type {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: ${({ theme }) => theme.margins[4]};
      button {
        transition: 400ms ease-in-out;
        padding: 0 ${({ theme }) => theme.paddings[10]};
        height: 40px;
        border: none;
        cursor: pointer;
        &:hover {
          svg {
            g {
              stroke: ${({ theme }) => theme.colors.switchActive};
            }
          }
        }

        svg {
          width: 20px;
          margin-top: 2px;
          g {
            stroke: ${({ theme }) => theme.colors.primary};
            stroke-width: 5;
            transition: 200ms ease-in-out;
          }
        }
      }
      p {
        font: ${({ theme }) => theme.fonts.inter.regular};
        font-weight: 600 !important;
        color: ${({ theme }) => theme.colors.primary};
        padding: ${({ theme }) => theme.paddings[1]}
          ${({ theme }) => theme.paddings[8]};
        border: 1px solid ${({ theme }) => theme.colors.inputLine};
        width: 120px;
        text-align: center;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &:nth-of-type(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 24px;
      ${ToggleArrow} {
        display: block;
        &:first-of-type {
          transform: rotate(90deg);
        }
        &:last-of-type {
          transform: rotate(-90deg);
        }
      }
      p {
        font: ${({ theme }) => theme.fonts.inter.regular};
        font-weight: 600 !important;
        color: ${({ theme }) => theme.colors.primaryOpaque};
      }
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    margin: ${({ theme }) => theme.margins[8]} 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    margin: 0 !important;
  }
`;
