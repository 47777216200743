import { withTheme } from "styled-components";

import { StyledInput } from "../../../index";
import {
  InputsWrapper,
  ItemContainer,
  RevenuItemContainer,
  Warning,
} from "./revenusItemStyles";
import { useSearchParams } from "react-router-dom";
import { getRevenuLabel } from "utils/helpers/dossierNumeriqueHelper";
import { AVIS_IMPOSITION } from "utils/constantes";

import AttachmentContainer from "components/attachmentContainer/attachmentContainer";
import DemandeCheckbox from "../../../demandes/demandeDashboard/demandeAction/demandeCheckbox";

const ModifiedRevenuItem = ({
  revenus,
  attachedPieceNumerique,
  formikProps,
  blocked,
  avisAcknowledged,
  dossierNumeriqueId,
  setAvisAcknowledged = () => {},
}) => {
  const [_, setSearchParams] = useSearchParams();

  const handleNoFileClick = () => {
    setSearchParams({ category: "pieces" });
  };

  const { values, errors, touched, handleChange, handleBlur, isSubmitting } =
    formikProps;

  return (
    <RevenuItemContainer>
      <ItemContainer>
        <AttachmentContainer
          piece={attachedPieceNumerique}
          shouldShowActions={false}
          displayLabel={false}
          uploadNotAllowed={true}
          onClickAction={handleNoFileClick}
          dossierNumeriqueId={dossierNumeriqueId}
        />
      </ItemContainer>

      <InputsWrapper>
        {revenus.map((item, index) => (
          <StyledInput
            key={index}
            type="money"
            label={getRevenuLabel(item.typeRevenu)}
            name={item.typeRevenu}
            defaultValue={values[item.typeRevenu]}
            errors={errors[item.typeRevenu]}
            touched={touched[item.typeRevenu]}
            action={handleChange}
            handleBlur={handleBlur}
            disabled={
              isSubmitting ||
              blocked ||
              (attachedPieceNumerique.type === AVIS_IMPOSITION &&
                avisAcknowledged)
            }
            required
          />
        ))}
        {attachedPieceNumerique.type === AVIS_IMPOSITION && (
          <Warning>
            <DemandeCheckbox
              selected={avisAcknowledged}
              selectAction={(bool) => setAvisAcknowledged(bool)}
            />
            <p>
              Attention si le revenu fiscal de référence ne correspond plus à la
              situation actuelle du candidat (ex : séparation) MERCI de cocher
              la case suivante
            </p>
          </Warning>
        )}
      </InputsWrapper>
    </RevenuItemContainer>
  );
};

export default withTheme(ModifiedRevenuItem);
