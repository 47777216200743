import { useKeycloak } from "@react-keycloak/web";
import { lazy, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router";
import { Header, Spinner, IsAuthWrapper, RenderIf } from "./components";
import { UTILISATEUR_LOAD } from "./utils/saga/actions";
import CookiesPopup from "./components/popups/cookiesPopup/cookiesPopup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "./hooks/useScrollToTop";
import CapaciteDetails from "components/forms/synthese/syntheseStatus/capaciteDetails/capaciteDetails";
import { useCallback } from "react";
import Sidebar from "components/navigation/sidebar/sidebar";
import Footer from "./components/navigation/footer/footer";
import {
  ROLE_ADB,
  ROLE_COURTIER,
  ROLE_LOQK,
  ROLE_PARTICULIER
} from "utils/security/keycloakUtils";
import { selectActiveUser } from "redux/user/userReducer";
import {
  TYPE_PARTICULIER_BAILLEUR,
  TYPE_PARTICULIER_CANDIDAT,
} from "utils/constantes";
import NotFound from "pages/statusPages/notFound";
import Unauthorized from "pages/statusPages/unauthorized";

const DemandeRouting = lazy(() => import("modules/demandes/routing"));
const CourtierRouting = lazy(() => import("modules/courtier/routing"));
const UtilisateurRouting = lazy(() => import("modules/utilisateur/routing"));
const BailleurRouting = lazy(() => import("modules/bailleur/routing"));

const AboutUs = lazy(() => import("pages/general/about/about"));
const PersonalData = lazy(() =>
  import("pages/general/personalData/personalData")
);
const LegalPage = lazy(() => import("pages/general/legal/legal"));
const DataConsentPage = lazy(() => import("pages/consent/consent"));
const LearnMore = lazy(() => import("pages/general/learnMore/learnMore"));

const FormsPage = lazy(() => import("./pages/dossierLocation/forms/forms.jsx"));

const CreateDossierForm = lazy(() =>
  import("./pages/dossierLocation/createDossier/createDossierForm.jsx")
);
const DashboardAdb = lazy(() =>
  import("./pages/adb/dashboardAbd/dashboardAdb")
);
const DashboardClient = lazy(() =>
  import("./pages/client/dashboardclient/dashboardclient")
);

const SelectAdbSite = lazy(() =>
  import("./pages/adb/selectSiteAdb/selectSiteAdb")
);

const RegisterClientForm = lazy(() =>
  import("./pages/client/registerClient/registerClientForm")
);

const HomepageRenderer = lazy(() =>
  import("./pages/general/homepage/homepageRenderer")
);
const RegistrationPage = lazy(() => import("./pages/register/register"));
const RegistrationThanks = lazy(() =>
  import("./pages/register/registerThanks")
);

function App() {
  const { keycloak, initialized } = useKeycloak();
  const [shouldDisplaySidebar, setShouldDisplaySidebar] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const connectedUser = useSelector(selectActiveUser);

  useEffect(() => {
    window.scrollTo(0, 0, { behavior: "smooth" });
    document.querySelector("body").style.overflow = "auto";
  }, [location]);

  const onAuthSuccess = useCallback(() => {
    dispatch({ type: UTILISATEUR_LOAD });
  }, [dispatch]);

  const setKeycloakActions = useCallback(() => {
    if (keycloak) {
      keycloak.onAuthSuccess = () => {
        onAuthSuccess();
      };

      keycloak.onTokenExpired = () => {
        keycloak.updateToken(30);
      };

      keycloak.onAuthRefreshError = () => {
        keycloak.login({
          redirectUri: window.location.origin + "/dashboard",
        });
      };

      keycloak.onAuthRefreshSuccess = () => {
        console.log("onAuthRefreshSuccess");
      };
    }
  }, [keycloak, initialized]);

  useEffect(() => {
    setKeycloakActions();
  }, [setKeycloakActions]);

  useEffect(() => {
    const path = location.pathname;
    const pathArray = path.split("/");
    console.log("pathArray", pathArray);
    console.log("connectedUser", connectedUser);
    if (
      pathArray[1] === "monespace" ||
      pathArray[1] === "candidate" ||
      pathArray[1] === "utilisateur" ||
      pathArray[1] === "dashboard" ||
      pathArray[1] === "courtier" ||
      (pathArray[1] === "demande-candidature" &&
        connectedUser?.typeCompte !== TYPE_PARTICULIER_CANDIDAT) ||
      (pathArray[1] === "bailleur" &&
        connectedUser?.typeCompte === TYPE_PARTICULIER_BAILLEUR)
    ) {
      setShouldDisplaySidebar(true);
    } else {
      setShouldDisplaySidebar(false);
    }
  }, [location, connectedUser]);

  return (
    <div>
      <ScrollToTop />
      <ToastContainer
        newestOnTop={true}
        pauseOnFocusLoss={false}
        limit={5}
        style={{
          zIndex: 100000,
        }}
      />
      <CookiesPopup />
      <RenderIf condition={!shouldDisplaySidebar}>
        <Header />
      </RenderIf>
      <RenderIf condition={shouldDisplaySidebar}>
        {/* <RenderIfNotRoles roles={[ROLE_PARTICULIER]}> */}
        <Sidebar />
        {/* </RenderIfNotRoles> */}
      </RenderIf>
      <Suspense
        fallback={<Spinner message="Chargement en cours" marginY="172px" />}
      >
        <Routes>
          <Route index path="/" element={<HomepageRenderer />} />
          <Route path="/register" element={<RegistrationPage />} />
          <Route path="/register-thanks" element={<RegistrationThanks />} />

          <Route
            path="/consentement"
            element={
              <IsAuthWrapper
                roles={[ROLE_PARTICULIER]}
              >
                <DataConsentPage />
              </IsAuthWrapper>
            }
          />
          <Route
            path="dashboard"
            element={
              <IsAuthWrapper>
                <Spinner />
              </IsAuthWrapper>
            }
          />

          <Route path="/candidate">
            <Route
              path="dashboard"
              element={
                <IsAuthWrapper fallbackLink={"/dashboard"} roles={[ROLE_ADB]}>
                  <DashboardAdb />
                </IsAuthWrapper>
              }
            />
            <Route
              path="forms"
              element={
                <IsAuthWrapper
                  fallbackLink="/non-autorise"
                  requiredType="candidate"
                >
                  <FormsPage />
                </IsAuthWrapper>
              }
            />
          </Route>
          <Route path="/monespace">
            <Route
              path="dashboard"
              element={
                <IsAuthWrapper
                  roles={[ROLE_LOQK, ROLE_COURTIER]}
                  fallbackLink={"/dashboard"}
                >
                  <DashboardClient />
                </IsAuthWrapper>
              }
            />
            <Route
              path="administrateur-bien"
              element={
                <IsAuthWrapper>
                  <DashboardAdb />
                </IsAuthWrapper>
              }
            />
            <Route path="dossier">
              <Route
                path="create"
                element={
                  <IsAuthWrapper fallbackLink={"/dashboard"}>
                    <CreateDossierForm />
                  </IsAuthWrapper>
                }
              />
              <Route
                path="status"
                element={
                  <IsAuthWrapper
                    fallbackLink={"/monespace/login"}
                    requiredType="admin"
                  >
                    <FormsPage />
                  </IsAuthWrapper>
                }
              />
              <Route
                path="select"
                element={
                  <IsAuthWrapper fallbackLink={"/dashboard"}>
                    <SelectAdbSite />
                  </IsAuthWrapper>
                }
              />
            </Route>

            <Route path="client">
              <Route path="enregistrement" element={<RegisterClientForm />} />
              <Route path="edition" element={<RegisterClientForm modify />} />
            </Route>
          </Route>
          <Route path="/utilisateur/*" element={<UtilisateurRouting />} />
          <Route path="/demande-candidature/*" element={<DemandeRouting />} />
          <Route path="/courtier/*" element={<CourtierRouting />} />
          <Route path="/bailleur/*" element={<BailleurRouting />} />

          <Route
            path="location/:locationId/capacite-details"
            element={
              <IsAuthWrapper>
                <CapaciteDetails />
              </IsAuthWrapper>
            }
          />

          <Route path="/qui-sommes-nous" element={<AboutUs />} />
          <Route path="/donnees-personnelles" element={<PersonalData />} />
          <Route path="/mentions-legales" element={<LegalPage />} />
          <Route path="/en-savoir-plus" element={<LearnMore />} />
          <Route path="/non-autorise" element={<Unauthorized />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>

      <RenderIf condition={!shouldDisplaySidebar}>
        <Footer />
      </RenderIf>
    </div>
  );
}

export default App;
