import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withTheme } from "styled-components";
import {
  FilesUnique,
  FilesUploadContainer,
} from "pages//dossierLocation/forms/formsStyles";

import { LocalNavigation, Spinner } from "components";

import {
  selectCurrentDossierNumerique,
  selectCurrentDossierRevenus,
} from "redux/dossierLocation/dossierLocationReducer";
import FilesIdentite from "./files/filesIdentite";
import FilesSalaire from "./files/filesSalaire";
import { LANCER_PARCOURS_NETHEOS } from "utils/saga/actions";

import FileUniqueContainer from "./files/fileUniqueContainer";
import { useLocation } from "react-router";
import dossierLocationMethodsWrapper from "utils/wrappers/dossierLocationMethodsWrapper";
import { useSearchParams } from "react-router-dom";
import { selectActiveUser } from "redux/user/userReducer";
import { TYPE_PARTICULIER_CANDIDAT } from "utils/constantes";
import { useFilesFormContext } from "utils/context/filesFormContext";

const FilesForm = ({
  getAttachmentFileTitle = () => {},
  updateIdentity = () => {},
  isLoading,
  setIsLoading,
  documentActions,
}) => {
  const dispatch = useDispatch();
  const dossierRevenus = useSelector(selectCurrentDossierRevenus);
  const dossierNumerique = useSelector(selectCurrentDossierNumerique);
  const [message, setMessage] = useState();
  const [_, setSearchParams] = useSearchParams();
  const location = useLocation();
  const user = useSelector(selectActiveUser);
  const { autresPieces, fichesPaie, processRunning } = useFilesFormContext();

  const lancerParcoursNetheos = (category) => {
    setMessage("Traitement et analyse des documents");
    console.log("lancerParcoursNetheos", category);
    dispatch({
      type: LANCER_PARCOURS_NETHEOS,
      onSuccess: () => {
        setSearchParams({
          category,
        });
      },
      onChange: setIsLoading,
    });
  };

  return isLoading ? (
    <Spinner message={message} />
  ) : (
    <FilesUploadContainer
      categoryColumns={fichesPaie && fichesPaie.length > 0 ? 3 : 2}
      salaryFiles={(fichesPaie && fichesPaie.length) || 0}
    >
      <div>
        <FilesIdentite
          getAttachmentFileTitle={getAttachmentFileTitle}
          updateIdentity={updateIdentity}
          dossierNumerique={dossierNumerique}
          documentActions={documentActions}
        />

        {fichesPaie && fichesPaie.length > 0 && (
          <FilesSalaire
            getAttachmentFileTitle={getAttachmentFileTitle}
            dossierNumerique={dossierNumerique}
            documentActions={documentActions}
          />
        )}
        <FilesUnique>
          {autresPieces &&
            autresPieces.length > 0 &&
            autresPieces.map((piece, index) => (
              <FileUniqueContainer
                key={index}
                piece={piece}
                getAttachmentFileTitle={getAttachmentFileTitle}
                dossierNumerique={dossierNumerique}
                documentActions={documentActions}
              />
            ))}
        </FilesUnique>
      </div>

      <LocalNavigation
        linkPrev={`${location.pathname}?category=situation`}
        isSubmitting={
          processRunning &&
          Object.values(processRunning).some((value) => value === true)
        }
        submitAction={() => {
          if (
            dossierRevenus &&
            dossierRevenus.revenus?.length > 0 &&
            user?.typeCompte !== TYPE_PARTICULIER_CANDIDAT
          ) {
            lancerParcoursNetheos("revenus");
          } else {
            lancerParcoursNetheos("synthese");
          }
        }}
      />
    </FilesUploadContainer>
  );
};

export default withTheme(dossierLocationMethodsWrapper(FilesForm));
