import {
  ButtonByStatus,
  CustomButton,
  OutlineButton,
  SubmitButton,
} from "./styledButtonStyles";

import { RenderIf, Spinner } from "components";
import { Link } from "react-router-dom";

import { ReactComponent as ChevronLeft } from "assets//icons/chevron_l.svg";
import { ReactComponent as ChevronRight } from "assets//icons/chevron_r.svg";

const StyledButton = ({
  isFlex,
  text = "Obtenir mon pass LOQK",
  type = "main",
  action = () => {},
  themeSizeSelector = ["montserrat", "large"],
  isSubmitting = false,
  themeSpacingSelector = [6, 14],
  themeWeight = "semiBold",
  link = "#",
  children,
  typeCategory = "success",
  svgType = "fill",
  disabled = false,
}) => {
  return (
    <>
      <RenderIf condition={type === "main"}>
        <CustomButton
          isFlex={isFlex}
          onClick={action}
          size={themeSizeSelector}
          weight={themeWeight}
          paddings={themeSpacingSelector}
          type="button"
          disabled={disabled}
        >
          {children ? children : text}
        </CustomButton>
      </RenderIf>
      <RenderIf condition={type === "submit"}>
        <SubmitButton
          type="submit"
          onClick={action}
          isFlex={isFlex}
          size={themeSizeSelector}
          weight={themeWeight}
          paddings={themeSpacingSelector}
          disabled={isSubmitting || disabled}
          isSubmitting={isSubmitting}
        >
          {isSubmitting ? (
            <Spinner marginY={0} messagePosition="row" />
          ) : children ? (
            children
          ) : (
            text
          )}
        </SubmitButton>
      </RenderIf>
      <RenderIf condition={type === "status"}>
        <ButtonByStatus
          isFlex={isFlex}
          size={themeSizeSelector}
          weight={themeWeight}
          paddings={themeSpacingSelector}
          type={typeCategory}
          onClick={action}
          disabled={isSubmitting || disabled}
          svgType={svgType}
        >
          {children ? children : text}
        </ButtonByStatus>
      </RenderIf>
      <RenderIf condition={type === "link"}>
        <Link to={link}>
          <CustomButton
            isFlex={isFlex}
            type="button"
            size={themeSizeSelector}
            weight={themeWeight}
            paddings={themeSpacingSelector}
          >
            {children ? children : text}
          </CustomButton>
        </Link>
      </RenderIf>
      <RenderIf condition={type === "outline"}>
        <Link to={link}>
          <OutlineButton
            isFlex={isFlex}
            type="button"
            size={themeSizeSelector}
            weight={themeWeight}
            paddings={themeSpacingSelector}
          >
            {children ? children : text}
          </OutlineButton>
        </Link>
      </RenderIf>
      <RenderIf condition={type === "linkPrev"}>
        <Link to={link}>
          <OutlineButton
            isFlex
            type="button"
            onClick={() => action()}
            size={themeSizeSelector}
            weight={themeWeight}
            paddings={themeSpacingSelector}
          >
            <ChevronLeft />
            Précedent
          </OutlineButton>
        </Link>
      </RenderIf>
      <RenderIf condition={type === "linkNext"}>
        <Link to={link}>
          <CustomButton
            isFlex
            type="button"
            onClick={() => action()}
            size={themeSizeSelector}
            weight={themeWeight}
            paddings={themeSpacingSelector}
          >
            Suivant
            <ChevronRight />
          </CustomButton>
        </Link>
      </RenderIf>
    </>
  );
};

export default StyledButton;
