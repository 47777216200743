import bailleurMethods from "modules/bailleur/methods";
import defaultDemandeMethods from "modules/demandes/methods";
import { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectActiveUser } from "redux/user/userReducer";
import {
  TYPE_PARTICULIER_BAILLEUR,
  TYPE_PARTICULIER_CANDIDAT,
} from "utils/constantes";

export const getMethodsByUserType = (userType) => {
  console.log("userType", userType);
  switch (userType) {
    case TYPE_PARTICULIER_BAILLEUR:
      return bailleurMethods;
    case TYPE_PARTICULIER_CANDIDAT:
      return bailleurMethods;
    default:
      return defaultDemandeMethods;
  }
};

const MethodsContext = createContext({
  methods: null,
  setMethods: () => {},
});

const useMethodsContext = () => {
  const { methods, setMethods } = useContext(MethodsContext);
  const user = useSelector(selectActiveUser);

  useEffect(() => {
    if (!user) {
      setMethods(null);
      return;
    }
    if (
      user &&
      (user?.typeCompte === TYPE_PARTICULIER_BAILLEUR ||
        user?.typeCompte === TYPE_PARTICULIER_CANDIDAT)
    ) {
      setMethods(bailleurMethods);
    } else {
      setMethods(defaultDemandeMethods);
    }
  }, [user]);

  return {
    methods,
    setMethods,
    getMethodsByUserType,
  };
};

const MethodsContextProvider = ({ children }) => {
  const [methods, setMethods] = useState(null);

  return (
    <MethodsContext.Provider value={{ methods, setMethods }}>
      {children}
    </MethodsContext.Provider>
  );
};

export { MethodsContextProvider, useMethodsContext };
