import { createContext, useContext, useState } from "react";
import { useParams } from "react-router";
import { APIService } from "services/apiService";
import { getDemandeCandidature } from "utils/api/demandes";
import { getMethodsByUserType } from "./methodsContext";
import { useSelector } from "react-redux";
import { selectActiveUser } from "redux/user/userReducer";
import { selectAdministrateurBien } from "redux/administrateurBien/administrateurBienReducer";

const DemandesContext = createContext();

const useDemandesContext = () => {
  const context = useContext(DemandesContext);

  return context;
};

const DemandesContextProvider = ({ children }) => {
  const [demandes, setDemandes] = useState([]);
  const [demande, setDemande] = useState(null);
  const [activeCandidate, setActiveCandidate] = useState(null);
  const [typeCandidature, setTypeCandidature] = useState(null);
  const [demandeCandidates, setDemandeCandidates] = useState([]);
  const [loadingDemande, setLoadingDemande] = useState(true);
  const { demandeId } = useParams();

  const user = useSelector(selectActiveUser);
  const adb = useSelector(selectAdministrateurBien);

  const reloadDemande = () => {
    setLoadingDemande(true);
    const { id: candidateId } = activeCandidate || {};
    let idDemande = demandeId || demande?.id;
    if (!idDemande) return;
    getMethodsByUserType(user?.typeCompte)?.getDemande(
      idDemande,
      {
        onSuccess: (demandeData) => {
          setDemande(demandeData);
          if (demandeData.dossierCandidature) {
            APIService({
              apiEndpoint: getDemandeCandidature,
              urlParams: [demandeData.id, demandeData.dossierCandidature.id],
              onSuccess: (candidature) => {
                setDemandeCandidates(candidature?.candidats);

                let candidateMatch = candidature.candidats.find(
                  (candidate) => candidate.id === candidateId
                );
                if (!candidateMatch) {
                  candidateMatch = candidature.candidats[0];
                }

                setActiveCandidate(candidateMatch);
                setLoadingDemande(false);
              },
            });
          } else {
            setLoadingDemande(false);
          }
        },
      },
      adb?.id
    );
  };

  const reloadDemandeState = () => {
    const { id: candidateId } = activeCandidate || {};
    getMethodsByUserType(user?.typeCompte)?.getDemande(
      demandeId,
      {
        onSuccess: (demandeData) => {
          setDemande({
            ...demande,
            statutCandidature: demandeData.statutCandidature,
          });
          if (demandeData.dossierCandidature) {
            APIService({
              apiEndpoint: getDemandeCandidature,
              urlParams: [demandeData.id, demandeData.dossierCandidature.id],
              onSuccess: (candidature) => {
                let activeCandidateMatch = candidature.candidats.find(
                  (candidate) => candidate.id === candidateId
                );
                if (activeCandidateMatch) {
                  setActiveCandidate({
                    ...activeCandidate,
                    statut: activeCandidateMatch.statut,
                  });
                }
              },
            });
          }
        },
      },
      adb?.id
    );
  };

  const value = {
    demandes,
    setDemandes,
    demande,
    setDemande,
    activeCandidate,
    setActiveCandidate,
    typeCandidature,
    setTypeCandidature,
    demandeCandidates,
    setDemandeCandidates,
    loadingDemande,
    reloadDemande,
    reloadDemandeState,
  };

  return (
    <DemandesContext.Provider value={value}>
      {children}
    </DemandesContext.Provider>
  );
};

export { DemandesContextProvider, useDemandesContext };
