import styled, { css } from "styled-components";

const sharedStyles = css`
  font: ${(props) => props.theme.fonts[props.size[0]][props.size[1]]};
  padding: ${(props) => props.theme.paddings[props.paddings[0]]}
    ${(props) => props.theme.paddings[props.paddings[1]]};
  border-radius: 8px;
  font-weight: ${(props) => props.theme.fontWeights[props.weight]} !important;
  cursor: pointer;
  gap: 16px;
  transition: 300ms ease-in-out;
`;

export const CustomButton = styled.button`
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.textOnPrimary};
  ${sharedStyles}
  strong {
    font-weight: ${(props) => props.theme.fontWeights[props.weight]} !important;
    color: ${(props) => props.theme.colors.secondary};
  }
  display: ${(props) => (props.isFlex ? "flex" : "block")};
  align-items: center;
  justify-content: center;
  transition: 300ms ease-in-out;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

export const OutlineButton = styled.button`
  border: 1px solid ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.primary};
  ${sharedStyles};
  display: ${(props) => (props.isFlex ? "flex" : "block")};
  align-items: center;
  justify-content: center;
`;

export const ButtonByStatus = styled.button`
  ${sharedStyles}
  display: ${(props) => (props.isFlex ? "flex" : "block")};
  align-items: center;
  justify-content: center;
  transition: 300ms ease-in-out;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: visible;
    `};
  & > div {
    transform: translateY(4px);
  }
  &:hover {
    & > div {
      color: ${({ theme }) => theme.colors.textOnPrimary} !important;
      & > div {
        color: ${({ theme }) => theme.colors.textOnPrimary} !important;
        &,
        &::before,
        &::after {
          background: ${({ theme }) => theme.colors.textOnPrimary} !important;
        }
      }
    }
  }
  ${({ type, theme }) => {
    if (type === "success")
      return css`
        border: 1px solid ${theme.colors.secondary};
        color: ${theme.colors.secondary};
        svg {
          path {
            ${({ svgType }) => svgType}: ${theme.colors.secondary};
            transition: 300ms ease-in-out;
          }
        }
        & > div {
        }
        &:hover {
          background: ${theme.colors.secondary};
          color: ${theme.colors.textOnPrimary};
          svg {
            path {
              ${({ svgType }) => svgType}: ${theme.colors.textOnPrimary};
            }
          }
        }
      `;
    else if (type === "error") {
      return css`
        border: 1px solid ${theme.colors.error};
        color: ${theme.colors.error};
        svg {
          path {
            ${({ svgType }) => svgType}: ${theme.colors.error};
            transition: 300ms ease-in-out;
          }
        }
        &:hover {
          background: ${theme.colors.error};
          color: ${theme.colors.textOnPrimary};
          svg {
            path {
              ${({ svgType }) => svgType}: ${theme.colors.textOnPrimary};
            }
          }
        }
      `;
    } else if (type === "default") {
      return css`
        border: 1px solid ${theme.colors.primary};
        color: ${theme.colors.primary};
        svg {
          path {
            fill: ${theme.colors.primary};
            transition: 300ms ease-in-out;
          }
        }
        &:hover {
          background: ${theme.colors.primary};
          color: ${theme.colors.textOnPrimary};
          svg {
            path {
              fill: ${theme.colors.textOnPrimary};
            }
          }
        }
      `;
    } else if (type === "primary") {
      return css`
        border: 1px solid ${theme.colors.primary};
        color: ${theme.colors.primary};
        svg {
          path {
            ${({ svgType }) => svgType}: ${theme.colors.primary};
            transition: 300ms ease-in-out;
          }
        }
        &:hover {
          background: ${theme.colors.primary};
          color: ${theme.colors.textOnPrimary};
          svg {
            path {
              ${({ svgType }) => svgType}: ${theme.colors.textOnPrimary};
            }
          }
        }
      `;
    }
  }}
`;

export const SubmitButton = styled.button`
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.textOnPrimary};
  ${sharedStyles}
  strong {
    font-weight: ${(props) => props.theme.fontWeights[props.weight]} !important;
    color: ${(props) => props.theme.colors.secondary};
  }
  display: ${(props) => (props.isFlex ? "flex" : "block")};
  align-items: center;
  justify-content: center;
  & > div {
    max-height: 20px;
    min-height: 20px;
    margin: 4px 0;
    & > div {
      max-height: 20px;
      min-height: 20px;
      margin: 4px;
      font-size: 4px;
      color: #fff;
      transform: scale(0.8);

      &,
      &:before,
      &:after {
        background: #fff;
      }
    }
  }

  ${({ disabled }) => {
    if (disabled)
      return css`
        opacity: 0.5;
        cursor: not-allowed;
      `;
  }}
`;
