//  DocumentUpload
//  Component that is displayed when there is no file uploaded
//  Props:
//     - uploadAction: function, the function to call when the file is uploaded
//     - id: string, the id of the file
//     - blocked: boolean, if the file is blocked or not

import { ReactComponent as UploadIcon } from "assets/icons/upload.svg";
import { ReactComponent as NoDocumentIcon } from "assets/icons/document_none.svg";
import { ReactComponent as Padlock } from "assets/icons/lock.svg";
import documentsIcon from "assets/icons/documents.webp";
import { withTheme } from "styled-components";
import {
  DocumentTextureContainer,
  NoFileUploaded,
  UploadContainer,
  UploadLock,
} from "pages/dossierLocation/forms/formsStyles";

const DocumentUpload = ({
  uploadAction = () => {},
  id,
  blocked,
  uploadNotAllowed,
}) => {
  return (
    <UploadContainer
      onClick={() => {
        if (uploadNotAllowed) {
          uploadAction();
        }
      }}
    >
      {blocked && (
        <UploadLock>
          <Padlock />
          <p>Vous n'êtes pas autorisé à modifier ce document</p>
          <img src={documentsIcon} alt="" />
        </UploadLock>
      )}
      <NoFileUploaded
        htmlFor={id}
        blocked={blocked}
        uploadNotAllowed={uploadNotAllowed}
      >
        <div>
          {uploadNotAllowed ? (
            <>
              <NoDocumentIcon />
              <p>Aucun document n'a été trouvé</p>
            </>
          ) : (
            <>
              <input
                type="file"
                id={id}
                name={id}
                onChange={(e) => uploadAction(e)}
                disabled={blocked}
              />
              <UploadIcon />
              <h2>Charger le document</h2>
              <p>Max. 10 Mb</p>
            </>
          )}
        </div>
        <DocumentTextureContainer>
          <img src={documentsIcon} alt="" />
        </DocumentTextureContainer>
      </NoFileUploaded>
    </UploadContainer>
  );
};

export default withTheme(DocumentUpload);
