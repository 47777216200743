import styled from "styled-components";

export const TypeSelectTitle = styled.h2`
  text-align: center;

  font: ${(props) => props.theme.fonts.montserrat.heading};
  font-weight: 600 !important;
  color: ${(props) => props.theme.colors.primary};
  margin: 0.75rem 0 1.5rem;
`;

export const TypeSelectText = styled.p`
  text-align: center;
  color: ${(props) => props.theme.colors.primary};
  font: ${(props) => props.theme.fonts.montserrat.regular};
  font-weight: 500 !important;
  width: 100%;
  max-width: 520px;
  margin-bottom: 2rem;
`;

export const TypeSelectCategory = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem 2rem;
  width: 100%;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: 1px solid ${({ theme }) => theme.colors.border};
  margin-bottom: 0.75rem;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 600 !important;
  font: ${(props) => props.theme.fonts.montserrat.regular};
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  span {
    margin-left: auto;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.primary};
    display: block;
    transition: all 0.3s ease-in-out;
    opacity: ${({ active }) => (active ? 1 : 0)};
  }
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
`;
