import { APIService } from "services/apiService";
import { bailleurInviteCandidate, importBailleurDemande } from "./api";
import { getDemandeDetails } from "utils/api/demandes";

const importDemande = (demandeId, handlers) => {
  APIService({
    apiEndpoint: importBailleurDemande,
    urlParams: [demandeId],
    onSuccess: (response) => {
      handlers.onSuccess && handlers.onSuccess(response);
    },
    onError: (error) => {
      console.log(error);
      handlers.onError && handlers.onError(error);
    },
    onChange: (status) => {
      handlers.onChange && handlers.onChange(status);
    },
  });
};

const inviteCandidat = (data, handlers) => {
  APIService({
    apiEndpoint: bailleurInviteCandidate,
    params: [data],
    onSuccess: (response) => {
      handlers.onSuccess && handlers.onSuccess(response);
    },
    onError: (error) => {
      console.log(error);
      handlers.onError && handlers.onError(error);
    },
    onChange: (status) => {
      handlers.onChange && handlers.onChange(status);
    },
  });
};

const getDemandeByID = async (demandeId, handlers) => {
  APIService({
    apiEndpoint: getDemandeDetails,
    urlParams: [demandeId],
    onSuccess: (data) => {
      handlers && handlers.onSuccess && handlers.onSuccess(data);
    },
    onError: (error) => {
      handlers && handlers.onError && handlers.onError(error);
    },
    onChange: (status) => {
      handlers && handlers.onChange && handlers.onChange(status);
    },
  });
};

const bailleurMethods = {
  importDemande,
  getDemande: getDemandeByID,
  inviteCandidat,
};

export default bailleurMethods;
