//  FilesIdentite
//  Component to display and handle the identity files in pieces form page
//  Props:
//     - typePieceIdentite: string, the type of the identity file
//     - piecesIdentites: array, the list of the identity files
//  State:
//     - confirmValue: string, the value of the new identity file type requested, used to trigger the confirmation popup
//     - files: array, the list of the identity files, sorted by alphabetical order
//  Functions:
//     - handleIdentityChange: function, the function to call to change the type of the identity file, runs when the user confirms the change in the confirmation popup
//     - triggerConfirmation: function, the function to call to trigger the confirmation popup in order to change the type of the identity file

import { useState, useEffect } from "react";
import { PIECES_IDENTITE_ENUM } from "utils/constantes";
import AttachmentContainer from "components/attachmentContainer/attachmentContainer";
import ConfirmationPopup from "components/popups/confirmationPopup/confirmationPopup";
import StyledSelect from "components/styledSelect/styledSelect";
import {
  FileCategoryContainer,
  FileCategoryHeading,
} from "pages/dossierLocation/forms/formsStyles";
import { useFilesFormContext } from "utils/context/filesFormContext";

const FilesIdentite = ({
  dossierNumerique,
  getAttachmentFileTitle = () => {},
  updateIdentity = () => {},
  documentActions,
  displayLabel = true,
}) => {
  const [confirmValue, setConfirmValue] = useState(false);
  const [files, setFiles] = useState([]);
  const { typePieceIdentite } = useFilesFormContext();
  const { forceBlock, piecesIdentites } = useFilesFormContext();

  useEffect(() => {
    // sorts the files by alphabetical order
    let filesSorted = piecesIdentites.sort((a, b) => {
      if (a.type > b.type) return 1;
      if (a.type < b.type) return -1;
      return 0;
    });
    setFiles(filesSorted);
  }, [piecesIdentites]);

  const triggerConfirmation = (value) => {
    setConfirmValue(value);
  };

  return (
    <FileCategoryContainer collapse={false}>
      <FileCategoryHeading>
        <h3>Justificatif d'identité</h3>
      </FileCategoryHeading>
      <ConfirmationPopup
        confirmTitle="Voulez-vous vraiment changer de type de pièce d'identité ?"
        confirmMessage="Vous allez perdre toutes les pièces d'identité que vous avez déjà ajoutées."
        handleConfirm={() => {
          updateIdentity(confirmValue, () => setConfirmValue(false));
          setConfirmValue(false);
        }}
        handleCancel={() => setConfirmValue(false)}
        showPopup={confirmValue}
      />

      <div>
        <StyledSelect
          action={(value) => triggerConfirmation(value)}
          blockChange={!confirmValue}
          defaultValue={typePieceIdentite}
          options={PIECES_IDENTITE_ENUM}
          disabled={
            // dossierNumerique.statut !== DossierNumeriqueStatut.EN_COURS ||
            forceBlock
          }
        />
        {files &&
          files.map((piece) => (
            <AttachmentContainer
              key={piece.id}
              piece={piece}
              getAttachmentFileTitle={getAttachmentFileTitle}
              dossierNumeriqueId={dossierNumerique?.id}
              documentActions={documentActions}
              displayLabel={displayLabel}
            />
          ))}
      </div>
    </FileCategoryContainer>
  );
};

export default FilesIdentite;
