//  AdvancedSearch
//  AdvancedSearch component for adb dashboard page
//  This component is used to filter dossier locations by different criteria
//  Props:
//     - updateAction: function to update the filters
//     - filterAction: function to apply the filters
//     - clearFilters: function to clear the filters
//     - sites: list of sites that the user has access to
//     - filters: filters object with all the filters values
//  State:
//     - showFilters: boolean to show or hide the filters
//     - sitesKey: list of sites with key and value to be used in the select component,
//                 this value cannot be changed

import { useState } from "react";
import { STATUT_DOSSIER_LOCATION } from "utils/constantes";
import { StyledButton, StyledSelect } from "components";
import { ReactComponent as Filter } from "assets//icons/filter.svg";
import { ReactComponent as SearchIcon } from "assets//icons/search.svg";
import { withTheme } from "styled-components";
import {
  AdvancedSearchContainer,
  FiltersContainer,
  SearchContainer,
  SearchFields,
  SearchInput,
  SelectContainer,
} from "./advancedSearchStyles";

const AdvancedSearch = ({
  updateAction = function () {},
  filterAction = function () {},
  clearFilters = function () {},
  sites = [],
  filters,
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const [sitesKey] = useState(
    sites.map((site) => ({
      key: site.reference,
      value: site.nom,
    }))
  );

  return (
    <AdvancedSearchContainer>
      <SearchContainer showFilters={showFilters}>
        <h3 onClick={() => setShowFilters(!showFilters)}>
          Recherche avancée
          <Filter />
        </h3>
        <FiltersContainer showFilters={showFilters}>
          <SearchFields columns={10} columnsDesktop={10} columnsTablet={4}>
            <SelectContainer
              colSpan={5}
              colSpanDesktop={5}
              colSpanTablet={4}
              colSpanMobile={4}
              rowSpan={1}
              rowSpanDesktop={1}
              rowSpanTablet={1}
              rowSpanMobile={1}
            >
              <StyledSelect
                label="Site"
                name="Site"
                options={sitesKey}
                action={(value) => updateAction("referenceSite", value)}
                shouldClear={filters.referenceSite === ""}
              />
            </SelectContainer>
            <SearchInput
              colSpan={5}
              colSpanDesktop={5}
              colSpanTablet={4}
              colSpanMobile={4}
              rowSpan={1}
              rowSpanDesktop={1}
              rowSpanTablet={2}
              rowSpanMobile={2}
            >
              <label htmlFor="">Référence Candidature</label>
              <div>
                <SearchIcon />
                <input
                  type="text"
                  placeholder=""
                  onChange={(e) =>
                    updateAction("referenceDossierLocation", e.target.value)
                  }
                  value={filters.referenceDossierLocation}
                />
              </div>
            </SearchInput>
            <SearchInput
              colSpan={5}
              colSpanDesktop={5}
              colSpanTablet={4}
              colSpanMobile={4}
              rowSpan={2}
              rowSpanDesktop={2}
              rowSpanTablet={3}
              rowSpanMobile={3}
            >
              <label htmlFor="">Nom locataire</label>
              <div>
                <SearchIcon />
                <input
                  type="text"
                  placeholder=""
                  onChange={(e) => updateAction("nomLocataire", e.target.value)}
                  value={filters.nomLocataire}
                />
              </div>
            </SearchInput>

            <SelectContainer
              colSpan={5}
              colSpanDesktop={5}
              colSpanTablet={4}
              colSpanMobile={4}
              rowSpan={2}
              rowSpanDesktop={2}
              rowSpanTablet={4}
              rowSpanMobile={4}
            >
              <StyledSelect
                label="Statut du dossier"
                name="StatusDossier"
                options={STATUT_DOSSIER_LOCATION}
                action={(value) => updateAction("statutDossierLocation", value)}
                shouldClear={filters.statutDossierLocation === ""}
              />
            </SelectContainer>
          </SearchFields>
          <div>
            <StyledButton
              type="status"
              typeCategory="error"
              action={() => clearFilters()}
              text={"Réinitialiser"}
              to="#"
              themeSizeSelector={["montserrat", "regular"]}
              themeSpacingSelector={[4, 8]}
            />
            <StyledButton
              type="status"
              typeCategory="success"
              action={() => filterAction(false)}
              text={"Lancer Recherche"}
              to="#"
              themeSizeSelector={["montserrat", "regular"]}
              themeSpacingSelector={[4, 8]}
            />
          </div>
        </FiltersContainer>
      </SearchContainer>
    </AdvancedSearchContainer>
  );
};

export default withTheme(AdvancedSearch);
