import { FetchDossierFacileDocument } from "modules/dossierFacile/api";
import { createContext, useContext, useState } from "react";
import { APIService } from "services/apiService";
import { fetchDocument } from "utils/api/fetchFiles";
import { useDemandesContext } from "./demandeContext";

const FilesFormContext = createContext({
  typePieceIdentite: "",
  piecesIdentites: [],
  fichesPaie: [],
  autresPieces: [],
  forceBlock: false,
  processRunning: {},
  documentActions: {
    handleGetDocument: () => {},
  },
  setTypePieceIdentite: () => {},
  setPiecesIdentites: () => {},
  setFichesPaie: () => {},
  setAutresPieces: () => {},
  setForceBlock: () => {},
  setProcessRunning: () => {},
});

const useFilesFormContext = () => {
  const contextData = useContext(FilesFormContext);
  const demandeContext = useDemandesContext();
  const isDossierCandidate = demandeContext !== undefined;

  const handleGetDocument = (
    dossierNumeriqueID,
    documentId,
    isImage,
    handlers
  ) => {
    if (isDossierCandidate) {
      APIService({
        apiEndpoint: FetchDossierFacileDocument,
        params: [dossierNumeriqueID, documentId, isImage],
        onChange: handlers?.onChange,
        onSuccess: handlers?.onSuccess,
        onErrorGeneral: handlers?.onError,
      });
    } else {
      APIService({
        apiEndpoint: fetchDocument,
        params: [dossierNumeriqueID, documentId, isImage],
        onSuccess: handlers?.onSuccess,
        onChange: handlers?.onChange,
        onErrorGeneral: (error) => {
          handlers?.onError(error);
        },
      });
    }
  };

  return {
    ...contextData,
    handleGetDocument: handleGetDocument,
  };
};

const FilesContextProvider = ({ children }) => {
  const [typePieceIdentite, setTypePieceIdentite] = useState("");
  const [piecesIdentites, setPiecesIdentites] = useState([]);
  const [forceBlock, setForceBlock] = useState(false);
  const [processRunning, setProcessRunning] = useState({});
  const [fichesPaie, setFichesPaie] = useState([]);
  const [autresPieces, setAutresPieces] = useState([]);
  const [documentActions, setDocumentActions] = useState({
    handleGetDocument: () => {},
  });

  return (
    <FilesFormContext.Provider
      value={{
        typePieceIdentite,
        setTypePieceIdentite,
        piecesIdentites,
        setPiecesIdentites,
        forceBlock,
        setForceBlock,
        processRunning,
        setProcessRunning,
        documentActions,
        setDocumentActions,
        fichesPaie,
        setFichesPaie,
        autresPieces,
        setAutresPieces,
      }}
    >
      {children}
    </FilesFormContext.Provider>
  );
};

export { FilesContextProvider, useFilesFormContext };
