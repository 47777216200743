//  SituationPersonelle
//  Form section for personal situation like number of children and their age, and the person's relationship status
//  State:
//     - nombreEnfants: number of children
//  Functions:
//     - updateNombreEnfants: function, updates the number of children to state and to Formik
//     - changingChildAge: function, updates the age of a child to state and to Formik

import { useState } from "react";
import { SITUATION_PERSO_ENUM } from "utils/constantes";
import { canSitatutPersonnelleShowMessage } from "../utils/dossierLocataireValidator";
import { FormSection, StyledSelect, StyledInput } from "../../index";
import { useSelector } from "react-redux";
import { selectCurrentTypeDossierLocataire } from "redux/dossierLocation/dossierLocationReducer";

const SituationPersonelle = ({
  values,
  errors,
  touched,
  handleChange,
  isSubmitting,
  setFieldValue,
  handleBlur,
  blocked,
}) => {
  const { situationPerso, enfants = [] } = values;
  const typeDossierLocataire = useSelector(selectCurrentTypeDossierLocataire);
  const [nombreEnfants, setNombreEnfants] = useState(
    enfants ? enfants.length : 0
  );

  const updateNombreEnfants = (value) => {
    const nbrEnfants = parseInt(value);
    if (blocked) {
      return;
    }

    if (isNaN(nbrEnfants) || nbrEnfants < 0 || nbrEnfants > 10) {
      setNombreEnfants(undefined);
      setFieldValue("enfants", []);
    } else {
      const newEnfants = Array(nbrEnfants).fill(0);
      setNombreEnfants(nbrEnfants);
      setFieldValue("enfants", newEnfants);
    }
  };

  const changingChildAge = (index, e) => {
    let nextVal = parseInt(e.target.value);
    if (blocked) {
      return;
    }

    if (isNaN(nextVal)) {
      nextVal = undefined;
    } else if (nextVal <= 0 || nextVal > 30) {
      nextVal = 0;
    }
    const newEnfants = [...enfants];
    newEnfants[index] = nextVal;
    setFieldValue("enfants", newEnfants);
  };

  return (
    <FormSection
      hasBorder
      title={"Situation Personnelle"}
      values={{
        situationPerso,
        enfants: enfants,
      }}
      isValid={canSitatutPersonnelleShowMessage({
        situationPerso,
        nombreEnfants,
      })}
      shouldShowInfoMessage={typeDossierLocataire === "CONJOINT"}
      infoMessage="Veuillez ne pas saisir les enfants qui ont été déja pris en compte dans la déclaration de votre conjoint."
    >
      <StyledSelect
        label="Situation Personnelle"
        name="situationPerso"
        required
        options={SITUATION_PERSO_ENUM}
        defaultValue={values.situationPerso}
        action={handleChange}
        setFieldValue={setFieldValue}
        disabled={isSubmitting || blocked}
        errors={errors?.situationPerso}
        touched={touched.situationPerso}
        handleBlur={handleBlur}
      />

      <StyledInput
        category="personal"
        type="number"
        placeholder="Nombre d'enfants"
        label="Nombre d'enfants"
        min="0"
        max="11"
        defaultValue={nombreEnfants}
        action={(e) => updateNombreEnfants(e.target.value)}
        errors={
          isNaN(nombreEnfants) || nombreEnfants > 10 || nombreEnfants < 0
            ? "veuillez saisir un nombre entre 0 et 10"
            : undefined
        }
        touched={true}
        disabled={isSubmitting || blocked}
      />

      {enfants &&
        enfants.map((_, i) => (
          <StyledInput
            key={i}
            name={`enfants[${i}]`}
            category="personal"
            type="number"
            placeholder={"Enfant " + (i + 1)}
            label={"Age de l'enfant " + (i + 1)}
            errors={errors && errors?.enfants && errors?.enfants[i]}
            touched={touched && touched?.enfants && touched?.enfants[i]}
            defaultValue={enfants[i]}
            disabled={isSubmitting || blocked}
            action={(e) => changingChildAge(i, e)}
            handleBlur={handleBlur}
            min={0}
          />
        ))}
    </FormSection>
  );
};

export default SituationPersonelle;
