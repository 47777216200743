import { call, put, takeLatest, select } from "redux-saga/effects";

import {
  selectCurrentDossierLocataire,
  setCurrentDossierLocataire,
  setCurrentDossierLocatairesList,
  setCurrentDossierLocation,
  setCurrentDossierNumerique,
  setCurrentDossierRevenus,
  setCurrentSite,
} from "redux//dossierLocation/dossierLocationReducer";
import { PREVIEW_SYNTHESE, SYNTHESE_LOAD } from "./actions";
import * as locationApi from "../api/dossierLocation";
import { selectAdministrateurBien } from "redux//administrateurBien/administrateurBienReducer";
import { toast } from "react-toastify";

function* setLocationDataForSynthese(action) {
  const { onChange = () => {} } = action.payload;
  try {
    onChange(true);
    const { locationData, navigateAction = () => {} } = action.payload;
    console.log(locationData);

    yield put(setCurrentDossierLocation(locationData));
    yield put(setCurrentSite(locationData.site));

    navigateAction();
  } catch (error) {
    console.error(error);
    toast.error(
      "Une erreur s'est produite, Veuillez contacter votre administrateur pour plus d'informations."
    );
  } finally {
    onChange(false);
  }
}

function* setDataForSynthese(action) {
  const {
    dossierLocation: locationData,
    navigateAction = () => {},
    onChange = () => {},
  } = action.payload;

  try {
    onChange(true);

    const { id: dossierLocationId, site } = locationData;
    const { id: adbId } = yield select(selectAdministrateurBien);
    const target = action.payload?.target;

    const response = yield call(
      locationApi.getDossierLocationDetails,
      adbId,
      site.id,
      dossierLocationId
    );

    const { data } = response;

    const { dossierLocataires, ...dossierLocation } = data;

    yield put(setCurrentDossierLocatairesList(dossierLocataires));

    // verify is there is a selected person
    let currentDossierLocataire = yield select(selectCurrentDossierLocataire);

    // checks if some dossier locataire exists in redux
    if (!currentDossierLocataire) {
      // checks if the current dossier locataire exists in the list of dossier locataires from the api
      currentDossierLocataire = dossierLocataires[0];
    } else {
      currentDossierLocataire = dossierLocataires.find(
        (docIt) => docIt.id === currentDossierLocataire.id
      );
    }

    if (target) {
      let match = dossierLocataires.find((docIt) => docIt.id === target);
      if (match) currentDossierLocataire = match;
    }

    const { dossierNumerique, revenus, ...dossierLocataire } =
      currentDossierLocataire;

    yield put(setCurrentDossierLocataire(dossierLocataire));
    yield put(setCurrentDossierRevenus(revenus));
    yield put(setCurrentDossierNumerique(dossierNumerique));
    yield put(setCurrentSite(site));
    yield put(setCurrentDossierLocation(dossierLocation));

    navigateAction();
  } catch (error) {
    toast.error(
      "Une erreur s'est produite, Veuillez contacter votre administrateur pour plus d'informations."
    );
  } finally {
    console.log("SYNTHESE LOAD END");
    onChange(false);
  }
}

function* callShowSynthese() {
  yield takeLatest(PREVIEW_SYNTHESE, setLocationDataForSynthese);
}

function* previewLocataire() {
  yield takeLatest(SYNTHESE_LOAD, setDataForSynthese);
}

export { previewLocataire, callShowSynthese };
