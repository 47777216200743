import { APIService } from "services/apiService";
import sendDemandeToUser, {
  getDemandeDetailsByID,
  importDemande,
} from "utils/api/demandes";

const defaultImportDemande = (demandeId, handlers, adbId) => {
  APIService({
    apiEndpoint: importDemande,
    urlParams: [adbId, demandeId],
    onSuccess: (data) => {
      handlers && handlers.onSuccess && handlers.onSuccess(data);
    },
    onError: (error) => {
      handlers && handlers.onError && handlers.onError(error);
    },
    onChange: (status) => {
      handlers && handlers.onChange && handlers.onChange(status);
    },
  });
};

const getDemandeByID = async (demandeId, handlers, adbId) => {
  APIService({
    apiEndpoint: getDemandeDetailsByID,
    urlParams: [demandeId, adbId],
    onSuccess: (data) => {
      handlers && handlers.onSuccess && handlers.onSuccess(data);
    },
    onError: (error) => {
      handlers && handlers.onError && handlers.onError(error);
    },
    onChange: (status) => {
      handlers && handlers.onChange && handlers.onChange(status);
    },
  });
};

const inviteCandidat = (data, handlers, adbID, siteId) => {
  APIService({
    apiEndpoint: sendDemandeToUser,
    params: [adbID, siteId, data],
    onSuccess: (response) => {
      handlers.onSuccess && handlers.onSuccess(response);
    },
    onError: (error) => {
      console.log(error);
      handlers.onError && handlers.onError(error);
    },
    onChange: (status) => {
      handlers.onChange && handlers.onChange(status);
    },
  });
};

const defaultDemandeMethods = {
  importDemande: defaultImportDemande,
  getDemande: getDemandeByID,
  inviteCandidat,
};

export default defaultDemandeMethods;
