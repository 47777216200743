import { lazy, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  selectCurrentDossierLocataire,
  selectCurrentDossierNumerique,
  selectCurrentDossierRevenus,
} from "redux/dossierLocation/dossierLocationReducer";
import ErrorBoundary from "components/layout/errorBoundary/errorBoundary";
import { ComponentWrapper, RenderIf } from "components";
import { selectDossierLocation } from "redux/dossierLocation/dossierLocationReducer";
import Spinner from "components/spinner/spinner";
import { FilesContextProvider } from "utils/context/filesFormContext";

const SituationPersonnelleForm = lazy(() => import("./personal"));
const FilesForm = lazy(() => import("./wrappers/dossierLocationFilesWrapper"));
const RevenusForm = lazy(() => import("./revenus"));
const SyntheseForm = lazy(() => import("./synthese"));

const FormsRenderer = ({ activeCategory, loading }) => {
  const dossierLocataire = useSelector(selectCurrentDossierLocataire);
  const dossierRevenus = useSelector(selectCurrentDossierRevenus);
  const dossierNumerique = useSelector(selectCurrentDossierNumerique);
  const currentDossierLocation = useSelector(selectDossierLocation);

  const getIsActive = (category) => {
    return activeCategory === category ? 1 : 0;
  };

  return (
    <FilesContextProvider>
      {loading ? (
        <Spinner />
      ) : (
        <ComponentWrapper hasMargin isDashboard ignoreTopPadding>
          <RenderIf condition={getIsActive("situation") && dossierLocataire}>
            <ErrorBoundary>
              <SituationPersonnelleForm dossierLocataire={dossierLocataire} />
            </ErrorBoundary>
          </RenderIf>
          <RenderIf condition={getIsActive("pieces") && dossierNumerique}>
            <FilesForm />
          </RenderIf>
          <RenderIf
            condition={
              getIsActive("revenus") &&
              dossierRevenus &&
              dossierRevenus.revenus?.length > 0
            }
          >
            <RevenusForm dossierRevenus={dossierRevenus} />
          </RenderIf>
          <RenderIf
            condition={getIsActive("synthese") && currentDossierLocation}
          >
            <SyntheseForm />
          </RenderIf>
        </ComponentWrapper>
      )}
    </FilesContextProvider>
  );
};

export default FormsRenderer;
