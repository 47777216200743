export const theme = {
  colors: {
    primary: "rgb(30, 39, 97)",
    primaryOpaque: "rgba(30, 39, 97, 0.5)",
    popupBackground: "rgba(30, 39, 97, 0.4)",
    inputLine: "rgba(30, 39, 97, 0.3)",
    hover: "rgb(30, 39, 97, 0.05)",
    disabled: "rgba(30, 39, 97, 0.3)",
    border: "rgba(30, 39, 97,0.1)",
    secondary: "#1cae3b",
    loqkOriginal: "#39ff14",
    success: "#179900",
    homeHeroBackground: "#F8F7F3",
    textOnPrimary: "#fff",
    error: "rgb(214, 56, 59)",
    warning: "#F9A825",
    switchActive: "#007ACC",
    switchInactive: "#E5E5E5",
    summary: "#FAFAFA",
    summaryBox: "#F5F5F5",
    garant: "#C58940",
    accepte: "#f99417",
    documentPopupBackground: "#EEF1F5",
    advantageIcon: "#E8EBFA",
    demandeDashboardHeader: "rgb(237, 239, 250,1)",
    demandeDashboardItem: "rgba(245, 246, 252, 0.65);",
    statutIntermediate: "#47b5ff",
    buttonAvailable: "rgb(71, 181, 255)",
    dossierFacile: "#1212ff",
  },
  fonts: {
    montserrat: {
      small: " 12px/20px Montserrat, serif",
      sub: "14px/22px Montserrat, serif",
      regular: "16px/24px Montserrat, serif",
      highlight: "20px/28px Montserrat, serif",
      large: "24px/32px Montserrat, serif",
      heading: "32px/40px Montserrat, serif",
      title: "40px/48px Montserrat, serif",
      pageTitle: "48px/56px Montserrat, serif",
      mainTitle: "56px/64px Montserrat, serif",
    },
    inter: {
      small: "12px/20px Inter, serif",
      sub: "14px/22px Inter, serif",
      regular: "16px/24px Inter, serif",
      highlight: "20px/28px Inter, serif",
      large: "24px/32px Inter, serif",
      heading: "32px/40px Inter, serif",
      title: "40px/48px Montserrat, serif",
      pageTitle: "48px/56px Inter, serif",
      mainTitle: "56px/64px Inter, serif",
    },
  },

  fontWeights: {
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
  },
  shadows: {
    lightDark: "0px 4px 8px rgba(30, 39, 98, 0.1);",
    dark: "2px 4px 12px rgba(30, 39, 98, 0.2), inset 3px 4px 12px rgba(30,39,98,0.05);",
  },
  margins: {
    1: "4px",
    2: "8px",
    3: "12px",
    4: "16px",
    5: "20px",
    6: "24px",
    7: "28px",
    8: "32px",
    9: "36px",
    10: "40px",
    11: "44px",
    12: "48px",
    13: "52px",
    14: "56px",
    15: "60px",
    16: "64px",
    17: "68px",
    18: "72px",
    19: "76px",
    20: "80px",
    21: "84px",
    22: "88px",
    23: "92px",
    24: "96px",
    25: "100px",
    26: "104px",
    27: "108px",
    28: "112px",
    29: "116px",
    30: "120px",
    31: "124px",
    32: "128px",
    33: "132px",
  },
  paddings: {
    1: "4px",
    2: "8px",
    3: "12px",
    4: "16px",
    5: "20px",
    6: "24px",
    7: "28px",
    8: "32px",
    9: "36px",
    10: "40px",
    11: "44px",
    12: "48px",
    13: "52px",
    14: "56px",
    15: "60px",
    16: "64px",
    17: "68px",
    18: "72px",
    19: "76px",
    20: "80px",
    21: "84px",
    22: "88px",
    23: "92px",
    24: "96px",
    25: "100px",
    26: "104px",
    27: "108px",
    28: "112px",
    29: "116px",
    30: "120px",
    31: "124px",
    32: "128px",
    33: "132px",
    34: "136px",
    35: "140px",
    36: "144px",
    37: "148px",
    38: "152px",
    39: "156px",
    40: "160px",
    41: "164px",
    42: "168px",
    43: "172px",
  },
  breakpoints: {
    mobile: "568px",
    small: "768px",
    smallMedium: "860px",
    medium: "1024px",
    tablet: "1280px",
    desktop: "1440px",
    largeDesktop: "1560px",
  },
  horizontalPaddings: {
    mobile: "32px",
    small: "48px",
    tablet: "72px",
    desktop: "128px",
    largeDesktop: "144px",
    default: "196px",
  },
};
