import { axiosInstance } from "utils/api/axiosInstance";

export const getBailleurDemandes = async () => {
  return axiosInstance.get("demandes-candidatures");
};

export const importBailleurDemande = async (demandeCandidatureId) => {
  return axiosInstance.post(
    `demandes-candidatures/${demandeCandidatureId}/importer`
  );
};

export const bailleurInviteCandidate = async (candidateData) => {
  return axiosInstance.post(
    "demandes-candidatures/invite-candidat",
    candidateData
  );
};
