import { call, takeLatest, put, select, takeEvery } from "redux-saga/effects";
import { selectAdministrateurBien } from "redux//administrateurBien/administrateurBienReducer";
import {
  selectCurrentDossierLocataire,
  selectDossierLocation,
  selectCurrentSite,
  setCurrentDossierLocataire,
  setListDossiersLocations,
  setCurrentDossierNumerique,
  setCurrentDossierLocation,
  selectCurrentTypeDossierLocataire,
  setCurrentDossierRevenus,
  selectCurrentDossierRevenus,
  selectCurrentDossierNumerique,
  setCurrentTypeDossierLocataire,
  setCurrentDossierLocatairesList,
  LOCATAIRE_INITIAL_VALUES,
} from "redux//dossierLocation/dossierLocationReducer";
import * as api from "../api/dossierLocation";
import {
  ACCEPTER_DOSSIER_LOCATION,
  AJOUTER_COLOCATAIRE,
  CREATE_OR_UPDATE_DOSSIER_LOCATAIRE,
  GET_LOCATION_CAPACITE_DETAILS,
  LANCER_PARCOURS_NETHEOS,
  LOAD_DOSSIERS_LOCATIONS,
  LOAD_DOSSIER_SUMMARY,
  REJETER_DOSSIER_LOCATION,
  ROUVRIR_DOSSIER_LOCATION,
  ROUVRIR_DOSSIER_PARTENAIRE,
  SEARCH_DOSSIER_BY_PASSLOCK,
  UPDATE_REVENUS,
  UPDATE_TYPE_PIECE_IDENTITE,
} from "./actions";
import { toast } from "react-toastify";
import { mapFormValuesToRevenus } from "../helpers/dossierNumeriqueHelper";
import { STATUT_INVALIDE } from "../situationStatutProConstantes";
import { CARTE_IDENTITE_TYPE } from "../constantes";
import {
  DossierLocataireStatut,
  DossierLocationStatut,
  DossierNumeriqueStatut,
} from "../Statuts";
import { getDossierNumerique } from "../api/fetchFiles";

function* getCapaciteLocativeDetails(action) {
  const { onProcessingChange } = action.payload;
  try {
    onProcessingChange(true);
    const { id: adbId } = yield select(selectAdministrateurBien);
    const { id: siteId } = yield select(selectCurrentSite);
    const { onSuccess, dossierLocationId } = action.payload;

    const response = yield call(
      api.getLocationCapaciteDetails,
      adbId,
      siteId,
      dossierLocationId
    );

    onSuccess(response.data);
  } catch (error) {
    console.log(error);
    toast.error("Une erreur est survenue");
  } finally {
    onProcessingChange(false);
  }
}

// Worker saga will be fired on USER_FETCH_REQUESTED actions
function* getDossierLocation(action) {
  const { onSuccess = () => {}, onChange = () => {} } = action.payload;
  try {
    const { adbId, filtre } = action.payload;
    onChange(true);
    const response = yield call(api.getDossiersLocations, adbId, filtre);
    yield put(setListDossiersLocations(response.data));
    onSuccess(response.data);
  } catch (error) {
    console.log(error);
    toast.error("Une erreur est survenue", {
      toastId: "getDossierLocation",
    });
  } finally {
    onChange(false);
  }
}

function* findDossierLocataire(action) {
  // TODO when passloqk is implemented we will look for the person using API
  const { navigate, dossierLocataire, setSubmitting } = action.payload;

  yield put(setCurrentDossierLocataire(dossierLocataire));

  navigate("/candidate/forms");

  setSubmitting(true);
}

function* verifyOrCreateDossierLocation() {
  const { id: adbId } = yield select(selectAdministrateurBien);
  const { id: siteId } = yield select(selectCurrentSite);
  const currentDossierLocation = yield select(selectDossierLocation);

  //const token = yield select(selectToken);

  if (!currentDossierLocation) {
    const response = yield call(api.createDossierLocation, adbId, siteId);

    yield put(setCurrentDossierLocation(response.data));
  }
}

function* modifyOrCreateDossierLocataire(values) {
  const { id: adbId } = yield select(selectAdministrateurBien);
  const { id: siteId } = yield select(selectCurrentSite);
  const currentDossierLocation = yield select(selectDossierLocation);
  let currentDossierLocataire = { ...values };
  let dossierLocataireId = currentDossierLocataire.id;

  //const token = yield select(selectToken);

  if (!dossierLocataireId) {
    // creer nouveau dossier locataire

    // recuprer le type du dossier
    const currentTypeDossierLocataire = yield select(
      selectCurrentTypeDossierLocataire
    );

    // mettre le type en cours de création
    currentDossierLocataire = {
      ...currentDossierLocataire,
      typeCandidat: currentTypeDossierLocataire,
    };

    const reponseLocataire = yield call(
      api.createDossierLocataire,
      adbId,
      siteId,
      currentDossierLocation.id,
      currentDossierLocataire
    );

    dossierLocataireId = reponseLocataire.data.id;
  } else {
    // update dossier locataire
    yield call(
      api.modifierDossierLocataire,
      adbId,
      siteId,
      currentDossierLocation.id,
      dossierLocataireId,
      currentDossierLocataire
    );
  }

  const dossierLoca = yield call(
    api.getDossierLocataireById,
    adbId,
    siteId,
    currentDossierLocation.id,
    dossierLocataireId
  );

  const { dossierNumerique, revenus, ...newDossierLocataire } =
    dossierLoca.data;

  yield put(setCurrentDossierLocataire(newDossierLocataire));
  yield put(setCurrentDossierNumerique(dossierNumerique));
  yield put(setCurrentDossierRevenus(revenus));
}

function* updateCurrentDossierLocation() {
  //const token = yield select(selectToken);
  const { id: adbId } = yield select(selectAdministrateurBien);
  const { id: siteId } = yield select(selectCurrentSite);
  const { id: dossierLocationId } = yield select(selectDossierLocation);

  const newDossierLocationResponse = yield call(
    api.getDossierLocation,
    adbId,
    siteId,
    dossierLocationId
  );
  yield put(setCurrentDossierLocation(newDossierLocationResponse.data));
}

function* createDossierLocataire(action) {
  const { navigate, setErrors, setSubmitting, values } = action.payload;

  setSubmitting(true);
  try {
    // verify if exist dossier location orElse create a new one
    yield call(verifyOrCreateDossierLocation);

    // verify if exist dossier locataire orElse update existing one
    yield call(modifyOrCreateDossierLocataire, values);

    // update the current dossier location
    // every changing could change the status
    yield call(updateCurrentDossierLocation);

    navigate();
  } catch (error) {
    toast.error(
      error?.response?.data?.message ||
        "une erreur s'est produite, veuillez vérifier les données saisies"
    );
    setErrors(error?.response?.data);
  } finally {
    setSubmitting(false);
  }
}

function* updateDossierLocataireRevenus(action) {
  const { revenusForm, setSubmitting, navigate = () => {} } = action.payload;

  try {
    setSubmitting(true);
    const { id: adbId } = yield select(selectAdministrateurBien);
    const { id: siteId } = yield select(selectCurrentSite);
    const { id: dossierLocationId } = yield select(selectDossierLocation);
    const { id: dossierLocataireId, statut } = yield select(
      selectCurrentDossierLocataire
    );

    if (statut !== DossierLocataireStatut.VALIDE) {
      const { revenus } = yield select(selectCurrentDossierRevenus);

      // we separate the negligeRevenuFiscal from the other parts
      const {
        negligeRevenuFiscal: isNegligeRevenuFiscalNegligeable,
        ...filledForm
      } = revenusForm;
      const revenusApiBody = mapFormValuesToRevenus(filledForm, revenus);

      yield call(
        api.updateRevenus,
        adbId,
        siteId,
        dossierLocationId,
        dossierLocataireId,
        {
          revenus: revenusApiBody,
          negligeRevenuFiscal: isNegligeRevenuFiscalNegligeable,
        }
      );
    }
    navigate();
  } catch (error) {
    toast.error(
      error?.response?.data?.message ||
        "une erreur s'est produite, veuillez vérifier les données saisies"
    );
  } finally {
    setSubmitting(false);
  }
}

function* runUpdateTypePieceIdentite(action) {
  const {
    typePieceIdentite,
    onChange = () => {},
    onSuccess = () => {},
    dossierNumerique,
    reduxDossierNumerique = false,
  } = action.payload;

  const { id: dossierNumeriqueId } = dossierNumerique;

  try {
    onChange(true);
    yield call(
      api.updateTypePieceIdentite,
      dossierNumeriqueId,
      typePieceIdentite
    );

    const response = yield call(getDossierNumerique, dossierNumeriqueId);

    if (reduxDossierNumerique) {
      yield put(setCurrentDossierNumerique(response.data));
    }
    onSuccess(response.data);
  } catch (error) {
    toast.error(
      "une erreur s'est produite. Veuillez contacter votre administrateur."
    );
  } finally {
    onChange(false);
  }
}

function* runLancerParcoursNetheos(action) {
  const { onChange = () => {} } = action;
  try {
    onChange(true);
    let { id: dossierLocataireId } = yield select(
      selectCurrentDossierLocataire
    );
    let { statut } = yield select(selectCurrentDossierNumerique);
    const { statut: statutDossierLocation } = yield select(
      selectDossierLocation
    );
    if (
      statut === DossierNumeriqueStatut.EN_COURS &&
      statutDossierLocation !== DossierLocationStatut.INVALIDE &&
      statutDossierLocation !== DossierLocationStatut.VALIDE
    ) {
      // prepare data call
      const { id: adbId } = yield select(selectAdministrateurBien);
      const { id: siteId } = yield select(selectCurrentSite);
      const { id: dossierLocationId } = yield select(selectDossierLocation);
      yield call(api.lancerDossierNetheos, dossierLocataireId);

      // implementing dossier blocking dossier location, we can change the state
      const dossierLocationResponse = yield call(
        api.getDossierLocation,
        adbId,
        siteId,
        dossierLocationId
      );
      const { data } = dossierLocationResponse;
      const { dossierLocataires, ...rest } = data;
      const currentLocataire = dossierLocataires.find(
        (locataire) => locataire.id === dossierLocataireId
      );
      const { revenus, dossierNumerique, ...dossierLocataire } =
        currentLocataire;
      yield put(setCurrentDossierLocataire(dossierLocataire));
      yield put(setCurrentDossierNumerique(dossierNumerique));
      yield put(setCurrentDossierRevenus(revenus));
      yield put(setCurrentDossierLocation(rest));
      yield put(setCurrentDossierLocatairesList(dossierLocataires));
    }
    action.onSuccess();
  } catch (error) {
    toast.error(
      error?.response?.data?.message ||
        "une erreur s'est produite. Veuillez contacter votre administrateur."
    );
  } finally {
    onChange(false);
  }
}

function* runRouvrirDossierPartenaire(action) {
  const { onSuccess = () => {}, onChange = () => {} } = action;

  try {
    let { id: dossierLocataireId } = yield select(
      selectCurrentDossierLocataire
    );
    let { statut } = yield select(selectCurrentDossierNumerique);
    const { statut: statutDossierLocation } = yield select(
      selectDossierLocation
    );
    onChange(true);

    if (
      statut === DossierNumeriqueStatut.INVALIDE &&
      statutDossierLocation !== DossierLocationStatut.INVALIDE &&
      statutDossierLocation !== DossierLocationStatut.VALIDE
    ) {
      // prepare data call
      const { id: adbId } = yield select(selectAdministrateurBien);
      const { id: siteId } = yield select(selectCurrentSite);
      const { id: dossierLocationId } = yield select(selectDossierLocation);
      yield call(api.rouvrirDossierLocataire, dossierLocataireId);

      const response = yield call(
        api.getDossierLocataireById,
        adbId,
        siteId,
        dossierLocationId,
        dossierLocataireId
      );

      const { revenus, dossierNumerique } = response.data;
      yield put(setCurrentDossierNumerique(dossierNumerique));
      yield put(setCurrentDossierRevenus(revenus));
    }
    onSuccess();
  } catch (error) {
    toast.error(
      "une erreur s'est produite. Veuillez contacter votre administrateur."
    );
  } finally {
    onChange(false);
  }
}

function* ajouterColocataire(action) {
  const { onAjouterChange = () => {}, onAjouterSuccess = () => {} } = action;
  const { typeCandidat, garanteId } = action.payload;
  onAjouterChange(true);

  try {
    let newLocataire = {
      ...LOCATAIRE_INITIAL_VALUES,
      garanteId,
      typeCandidat,
      enfants: [],
    };
    yield put(setCurrentDossierLocataire(newLocataire));
    yield put(
      setCurrentDossierNumerique({
        errors: [],
        pieces: [],
        statut: STATUT_INVALIDE,
        typePieceIdentite: CARTE_IDENTITE_TYPE,
      })
    );
    yield put(setCurrentTypeDossierLocataire(typeCandidat));
    yield put(setCurrentDossierRevenus([]));
    onAjouterSuccess();
  } catch (error) {
    toast.error(
      "une erreur s'est produite. Veuillez contacter votre administrateur."
    );
  } finally {
    onAjouterChange(false);
  }
}

function* loadSummary(action) {
  const {
    handleChange = () => {},
    handleData = () => {},
    handleError = () => {},
    dossierLocation,
  } = action.payload;
  try {
    handleChange(true);
    // get site from selected dossier location
    const { site } = dossierLocation;
    const { id: adbId } = yield select(selectAdministrateurBien);

    const response = yield call(
      api.getDossierLocationDetails,
      adbId,
      site.id,
      dossierLocation.id
    );

    handleData(response.data);
  } catch (error) {
    handleError(true);
  } finally {
    handleChange(false);
  }
}

function* accepteOrRejectDossierLocation(payload) {
  const { message, modificationType } = payload;
  try {
    const { id: siteId } = yield select(selectCurrentSite);
    const { id: adbId } = yield select(selectAdministrateurBien);
    const { id: dossierLocationId } = yield select(selectDossierLocation);

    yield call(
      api.accepteOrRejectDossierLocation,
      adbId,
      siteId,
      dossierLocationId,
      modificationType,
      message
    );

    yield call(reloadSynthesePage, { adbId, siteId, dossierLocationId });
  } catch (error) {
    toast.error(
      "Une erreur s'est produite. Veuillez contacter votre administrateur."
    );
  }
}

function* accepterDossierLocation(action) {
  const { message, onChange = () => {} } = action.payload;
  try {
    onChange(true);
    yield call(accepteOrRejectDossierLocation, {
      modificationType: "accepter-dossier",
      message,
    });
  } catch (error) {
  } finally {
    onChange(false);
  }
}
function* rejeterDossierLocation(action) {
  const { message, onChange = () => {} } = action.payload;
  try {
    onChange(true);
    yield call(accepteOrRejectDossierLocation, {
      modificationType: "rejeter-dossier",
      message,
    });
  } catch (error) {
  } finally {
    onChange(false);
  }
}

function* rouvrirDossierLocation(action) {
  const { onChange = () => {} } = action;
  try {
    onChange(true);
    const { id: adbId } = yield select(selectAdministrateurBien);
    const { id: siteId } = yield select(selectCurrentSite);
    const { id: dossierLocationId } = yield select(selectDossierLocation);

    yield call(api.rouvrirDossierLocation, adbId, siteId, dossierLocationId);

    yield call(reloadSynthesePage, { adbId, siteId, dossierLocationId });
  } catch (error) {
    toast.error(
      error?.response?.data?.message ||
        "Une erreur s'est produite. Veuillez contacter votre administrateur."
    );
  } finally {
    onChange(false);
  }
}

function* reloadSynthesePage(payload) {
  try {
    const { adbId, siteId, dossierLocationId } = payload;

    const response = yield call(
      api.getDossierLocationDetails,
      adbId,
      siteId,
      dossierLocationId
    );

    const { data } = response;
    const { dossierLocataires, ...dossierLocation } = data;
    yield put(setCurrentDossierLocatairesList(dossierLocataires));
    const { dossierNumerique, revenus, ...dossierLocataire } =
      dossierLocataires[0];

    yield put(setCurrentDossierLocataire(dossierLocataire));
    yield put(setCurrentDossierRevenus(revenus));
    yield put(setCurrentDossierNumerique(dossierNumerique));
    yield put(setCurrentDossierLocation(dossierLocation));
    toast.success(
      "Le dossier a été modifié avec succès. Veuillez vérifier les informations."
    );
  } catch (error) {}
}

function* runAccepterDossierLocation() {
  yield takeLatest(ACCEPTER_DOSSIER_LOCATION, accepterDossierLocation);
}

function* runRejeterDossierLocation() {
  yield takeLatest(REJETER_DOSSIER_LOCATION, rejeterDossierLocation);
}

function* runRouvrirDossierLocation() {
  yield takeLatest(ROUVRIR_DOSSIER_LOCATION, rouvrirDossierLocation);
}

function* runLoadSummary() {
  yield takeLatest(LOAD_DOSSIER_SUMMARY, loadSummary);
}

function* ajouterColocataireAction() {
  yield takeLatest(AJOUTER_COLOCATAIRE, ajouterColocataire);
}

function* updateTypePieceIdentite() {
  yield takeLatest(UPDATE_TYPE_PIECE_IDENTITE, runUpdateTypePieceIdentite);
}

function* updateRevenus() {
  yield takeLatest(UPDATE_REVENUS, updateDossierLocataireRevenus);
}

function* recupererDossiersLocation() {
  yield takeLatest(LOAD_DOSSIERS_LOCATIONS, getDossierLocation);
}

function* searchByPassLoqk() {
  yield takeLatest(SEARCH_DOSSIER_BY_PASSLOCK, findDossierLocataire);
}

function* createOrUpdateDossierLocataire() {
  yield takeEvery(CREATE_OR_UPDATE_DOSSIER_LOCATAIRE, createDossierLocataire);
}

function* lancerParcoursNetheos() {
  yield takeLatest(LANCER_PARCOURS_NETHEOS, runLancerParcoursNetheos);
}

function* lancerRouvertureDossierNetheos() {
  yield takeLatest(ROUVRIR_DOSSIER_PARTENAIRE, runRouvrirDossierPartenaire);
}

function* runGetCapaciteDetails() {
  yield takeLatest(GET_LOCATION_CAPACITE_DETAILS, getCapaciteLocativeDetails);
}

export {
  recupererDossiersLocation,
  searchByPassLoqk,
  createOrUpdateDossierLocataire,
  updateRevenus,
  updateTypePieceIdentite,
  lancerParcoursNetheos,
  lancerRouvertureDossierNetheos,
  ajouterColocataireAction,
  runLoadSummary,
  runAccepterDossierLocation,
  runRejeterDossierLocation,
  runRouvrirDossierLocation,
  runGetCapaciteDetails,
};
